import {useIntl} from 'react-intl';
import {usePageTitle} from '../../../../common';
import {
  EArchiveNotificationType,
  useGetEArchiveNotificationsLazyQuery,
  useRemoveEArchiveNotificationsMutation,
  useSetEArchiveNotificationsMutation,
} from '@symfonia-ksef/graphql';
import {useCallback, useEffect, useState} from 'react';
import {NotificationsList} from '../../../../common/components/NotificationsList/NotificationsList';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Breadcrumbs, ToastVariant} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {authState, earchiveState} from '@symfonia-ksef/state/rootRepository';

export const NotificationsCompanyPage = () => {
  const intl = useIntl();
  const {setPageTitle} = usePageTitle();
  const mutationContext = {context: {envId: earchiveState.company.companyId}};
  const [CompanyNotificationsKSeFEArchive, {loading}] = useGetEArchiveNotificationsLazyQuery(mutationContext);
  const [companyNotifications, setCompanyNotifications] = useState<Array<EArchiveNotificationType | undefined | null> | null | undefined>(undefined);
  setPageTitle(intl.formatMessage({id: Tr.emailNotification}));

  const [deleteeArchiveNotification] = useRemoveEArchiveNotificationsMutation(mutationContext);
  const [setEArchiveNotification] = useSetEArchiveNotificationsMutation(mutationContext);
  const addAlert = earchiveState.alertsState.addAlert;

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.settings}),
    },
    {
      label: intl.formatMessage({id: Tr.emailNotifications}),
    },
  ];

  const getNotifications = useCallback(async () => {
    setCompanyNotifications(undefined);
    try {
      const response = await CompanyNotificationsKSeFEArchive();
      const userNotifications = response?.data?.GetEArchiveNotifications?.Notifications;

      if (userNotifications !== undefined && userNotifications !== null) {
        setCompanyNotifications(userNotifications);
      }
    } catch (e) {
      console.debug(e);
    }
  }, [CompanyNotificationsKSeFEArchive]);

  const removeEArchiveNotification = async (n: EArchiveNotificationType) => {
    try {
      const result = await deleteeArchiveNotification({variables: {EArchiveNotificationType: n}});
      const response = result.data?.RemoveEArchiveNotifications?.Success;
      if (!response) addAlert(intl.formatMessage({id: Tr.emailNotificationError}), ToastVariant.ERROR);

      setCompanyNotifications(prevNotifications => prevNotifications?.filter(notification => notification !== n));
    } catch (e) {
      console.debug(e);
    }
  };

  const addEArchiveNotification = async (n: EArchiveNotificationType) => {
    try {
      const result = await setEArchiveNotification({variables: {EArchiveNotificationType: n}});
      const response = result.data?.SetEArchiveNotifications?.Success;
      if (!response) addAlert(intl.formatMessage({id: Tr.emailNotificationError}), ToastVariant.ERROR);

      if (companyNotifications) {
        setCompanyNotifications(prevNotifications => [...prevNotifications || [], n]);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <PageContent>
      <div className="flex flex-col gap-2 lg:ml-[40px]">
        <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'} testId="emailNotificationsPage-breadcrumbs"/>
        <div className="max-w-[650px]">
          <Header>{intl.formatMessage({id: Tr.emailNotifications})}</Header>
          <NotificationsList
            email={authState.currentUserEmail || ''}
            userNotifications={companyNotifications}
            removeNotification={n => removeEArchiveNotification(n as EArchiveNotificationType)}
            addNotification={n => addEArchiveNotification(n as EArchiveNotificationType)}
            loading={loading}
          />
        </div>
      </div>
    </PageContent>
  );
};
