import {AuthorizeInKSeFJobRunner} from '../../services/KSeFJobRunners/AuthorizeInKSeFJobRunner';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';

import {Base64Mapper, SzafirService, SzafirTask} from '@symfonia/szafir-react';
import {Tr} from '@symfonia-ksef/locales/keys';
import {
  AuthorizeInKSeFMutationVariables,
  GenerateInitRequestToLoginDocument,
  GenerateInitRequestToLoginMutation,
  GenerateInitRequestToLoginMutationVariables,
  WebsocketErrorType,
} from '@symfonia-ksef/graphql';
import {EventJobRunnerI} from '../../services/EventJobRunner';
import {RemoveAuthorizationInKSeFJobRunner} from '../../services/KSeFJobRunners/RemoveAuthorizationInKSeFJobRunner';
import {InitializerType} from '../../services/helpers/fetchMatchedAction';
import {addAlert} from '../../services/helpers/AlertService';
import {action, computed} from 'mobx';
import {JobRunnerManager, JobRunnerManagerI} from '../../services/JobRunnerManager';
import {authenticationState} from '../rootRepository';
import {errorsManager} from '../../services/ErrorHandlerServices/NotificationErrorServices/ErrorsManager';
import {
  graphqlErrorEventAdapterManager,
} from '../../services/ErrorHandlerServices/NotificationErrorServices/ErrorEventAdapters/GraphQLErrorEventAdapterManager';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {Repository} from '../../modules/root/services/MobXServices/Repository/Repository';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export enum KSeFAuthorizationSignatureAction {
  Authorize,
  RemoveAuthorize
}

export class AuthorizationInKSeFService extends Repository<'GenerateInitRequestToLogin', GenerateInitRequestToLoginMutation, GenerateInitRequestToLoginMutationVariables> {

  public errorType: WebsocketErrorType | undefined;

  private jobRunnerManager: JobRunnerManagerI<AuthorizeInKSeFMutationVariables>;

  private base64Mapper: Base64Mapper = new Base64Mapper();

  private szafirService?: SzafirService;

  constructor(public action: KSeFAuthorizationSignatureAction, envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super('GenerateInitRequestToLogin', GenerateInitRequestToLoginDocument, envObserver, earchiveState, InitializerType.Mutation);
    this.jobRunnerManager = new JobRunnerManager<AuthorizeInKSeFMutationVariables>(() => this.action === KSeFAuthorizationSignatureAction.Authorize ? new AuthorizeInKSeFJobRunner(envObserver, earchiveState) : new RemoveAuthorizationInKSeFJobRunner(envObserver, earchiveState), envObserver);

    if (!window.SZAFIR_SDK) {
      throw new Error(`Module 'SZAFIR_SDK' not found in the window object. Make sure you have linked the SDK library correctly.`);
    }
    this.szafirService = new SzafirService(window.SZAFIR_SDK);
  }

  @computed
  public get isPending(): boolean {
    return this.jobRunner.isPending;
  }

  private get jobRunner(): EventJobRunnerI<AuthorizeInKSeFMutationVariables> {
    return this.jobRunnerManager.jobRunner;
  }

  @action.bound
  public setErrorType(errorType: WebsocketErrorType | undefined): this {
    this.errorType = errorType;
    return this;
  }

  public setSzafirService(szafirService?: SzafirService): this {
    this.szafirService = szafirService;
    return this;
  }

  public override checkIsReady(): boolean {
    return !!this.envId && !!this.szafirService;
  }

  protected async beforeFetch(): Promise<void> {
    authenticationState.setLoading(true);
    await this.szafirService?.loadSdk?.();
    await this.szafirService?.loadSettings?.(`${window.location.origin}/assets/szafirsdk_web/settings.xml`);
  }

  protected override async onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): Promise<void> {
    authenticationState.setLoading(false);
    const {alertConfig} = errorsManager.get(graphqlErrorEventAdapterManager.createAdapter(errors[0]), {id: Tr.szafirErrorAuthentication});
    addAlert(alertConfig);
    // addAlert({id: Tr.szafirErrorAuthentication});

    await this.szafirService?.unloadSdk?.();
  }

  protected override async onSuccess(data: GenerateInitRequestToLoginMutation['GenerateInitRequestToLogin']): Promise<void> {
    try {
      if (!data) return;
      const szafirTask = new SzafirTask(this.base64Mapper.mapFromXml(data));
      const signedTask = await this.szafirService?.signSingleTask?.(szafirTask);
      authenticationState.setLoading(true);
      if (!signedTask?.signatureToCreate) {
        authenticationState.setLoading(false);
        await this.szafirService?.unloadSdk?.();
        return;
      }
      if (this.action === KSeFAuthorizationSignatureAction.RemoveAuthorize) {
        authenticationState.setProcessMessage(Tr.pendingUnauthorization);
      }
      await this.jobRunner.configure({InitSessionRequestSigned: signedTask?.signatureToCreate as string}).fetch();
      await this.szafirService?.unloadSdk?.();
    } catch (err) {
      authenticationState.setLoading(false);
      addAlert({id: Tr.szafirErrorAuthentication});
      await this.szafirService?.unloadSdk?.();
    } finally {
      authenticationState.setLoading(false);
    }
  }

  public override async onFetchRejected(): Promise<void> {
    try {
      authenticationState.setLoading(false);
      await this.szafirService?.unloadSdk?.();
      return;
    }
    catch (err) {
      authenticationState.setLoading(false);
    }
  }

  protected override async onAbort(): Promise<void> {
    try {
      authenticationState.setLoading(false);
      await this.szafirService?.unloadSdk?.();
      return;
    }
    catch (err) {
      authenticationState.setLoading(false);
    }
  }
}
