import React, {FC, Key, useCallback, useState} from 'react';
import {ConfirmModal, DropdownListOption, WithDropdownList} from '@symfonia/symfonia-ksef-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonSecondary, ButtonSecondarySize, IconSvg, Tooltip, TooltipPosition} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';

export enum ReportType {
  CSV = 'CSV',
  XLS = 'XLS'
}

const dropdown = Object.freeze({
  options: new Map<Key, DropdownListOption<ReportType>>()
    .set(ReportType.CSV, {
      label: intl.formatMessage({id: Tr.downloadCSV}),
      value: ReportType.CSV,
      lIcon: IconSvg.GET_APP,
    })
    .set(ReportType.XLS, {
      label: intl.formatMessage({id: Tr.downloadXLS}),
      value: ReportType.XLS,
      lIcon: IconSvg.GET_APP,
    }),
  selectable: false,
  tooltipDisabled: true,
});

export type OnReportConfirm = (reportType: ReportType | null) => Promise<void> | void

export type DownloadReportProps = {
  tooltipText?: string,
  disabled?: boolean,
  onConfirm: OnReportConfirm,
  filtersRequired?: boolean,
}

export const DownloadReport: FC<DownloadReportProps> = ({
                                                          tooltipText,
                                                          disabled,
                                                          onConfirm,
                                                          filtersRequired = true
                                                        }) => {

  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);

  const [reportType, setReportType] = useState<ReportType | null>(null);

  const closeModal = useCallback(() => {
    setReportType(null);
    setDownloadModalIsOpen(false);
  }, []);

  const openModal = useCallback((type: ReportType) => {
    setReportType(type);
    setDownloadModalIsOpen(true);
  }, []);

  const onDropdownChange = useCallback(([key]: Key[]) => {
    const {value} = dropdown.options.get(key) ?? {};
    value && openModal(value);
  }, [openModal]);

  const confirm = useCallback(() => {
    onConfirm(reportType);
    closeModal();
  }, [reportType, closeModal, onConfirm]);

  const button = <div>
    <WithDropdownList
      dropdown={dropdown}
      disabled={disabled}
      onChange={onDropdownChange}
    >
      <ButtonSecondary
        className="mx-[8px]"
        size={ButtonSecondarySize.SM}
        lIcon={IconSvg.SAVE_ALT}
        disabled={disabled}
        testId="DownloadInvoiceReportButton"
      />
    </WithDropdownList>
  </div>;

  return <>
    <ConfirmModal
      onCancel={closeModal}
      open={downloadModalIsOpen}
      onConfirm={confirm}
      contentClassName="report-dialog"
      title={intl.formatMessage({id: Tr.exportReportModalTitle}, {fileType: reportType})}
      confirmButtonName={intl.formatMessage({id: Tr.export})}
      cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
      content={filtersRequired ? intl.formatMessage({id: Tr.exportReportModalContent}) : intl.formatMessage({id: Tr.exportReportModalContentNoFilter})}
    />
    {disabled ? <Tooltip
      position={TooltipPosition.RIGHT}
      text={tooltipText ?? filtersRequired ? intl.formatMessage({id: Tr.DownloadInvoicesReport_Tooltip_Content}) : intl.formatMessage({id: Tr.DownloadInvoicesReport_Tooltip_Content_NoFilter})}>
      {button}
    </Tooltip> : button}
  </>;
};
