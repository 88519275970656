import {AddTenantButton} from '../AddTenantButton/AddTenantButton';
import {AppRoutes} from '../../../root/Routes';
import {useCallback, useState} from 'react';
import {isDesktop} from '../../../common/helpers/checkCurrentPlatform';
import {removeRowsPerPage} from '../../../common/hooks/useSavedRowsPerPageStorage';
import {EnvironmentType} from '@symfonia-ksef/graphql';
import {ContextSwitcher} from './ContextSwitcher/ContextSwitcher';
import {Tr} from '@symfonia-ksef/locales/keys';
import {isLoginHub} from '../../../common/helpers/checkCurrentPath';
import {localStorageService} from '../../../common/helpers/storage';
import {
  Avatar,
  AvatarSize,
  ButtonTertiary,
  ButtonTertiarySize,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  Separator,
  SeparatorSize,
} from '@symfonia/brandbook';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {authState, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {useIsRouteActive} from '../../../common';
import {earchiveStateMobx} from '../../../earchive';
import {useOutsideClick} from '@symfonia/utils';
import {useDeviceViewport} from '@symfonia/symfonia-ksef-components';
import {configMenuSections} from '../ConfigMenu/configMenuSections';
import {ConfigMenu} from '../ConfigMenu/ConfigMenu';
import {removeFilterItemsFromStorage} from '../../../common/helpers/removeFilterItemsFromStorage';

export interface UserMenuDropdownProps {
  userName: string | undefined;
  userSurname: string | undefined;
}

export const UserMenuDropdown = observer(({userName, userSurname}: UserMenuDropdownProps) => {
  const {
    company: {tenantId, companyId, currentEnvironment, removeStoredCompanyId},
    environments: {activeCompanies, activeTenants, adminTenant},
    tenant: {
      state: {
        accountManagementEnabled,
        addTenantEnabled,
        contextSwitcherEnabled,
        moduleEnvironmentType,
        rootTenant,
        selectedTenantForHub,
      },
    },
  } = earchiveState;

  const openDrawer = earchiveState.layoutState.openDrawer;
  const onClose = earchiveState.layoutState.setOpenDrawer;
  const manyTenantsAndCompanies = activeTenants.length >= 1 && activeCompanies.length > 1;

  const autoPostingInvoiceTypeGroup = 'AutoPostingInvoiceTypeGroup';
  const currentOrganizationIsActive = activeTenants.find(activeTenant => activeTenant.Id === tenantId);
  const setOpen = earchiveState.layoutState.setOpenDrawer;
  const intl = useIntl();
  const {isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);

  const [openSubMenu, setSubMenuOpen] = useState<boolean>(false);
  const availableSections = configMenuSections(intl, useIsRouteActive, earchiveStateMobx.moduleStatusStore.modules);
  const availableSectionsFiltered = availableSections.filter(
    s => !s.featureProps || (s.featureProps && s.featureProps.moduleStatus !== undefined),
  );

  const ref = useOutsideClick(() => {
    if (openDrawer) {
      setOpen(false);
    }
  });

  const handleLogout = useCallback(() => {
    removeRowsPerPage();
    if (!isDesktop) {
      earchiveState.tenant.removeStoredTenantId();
      earchiveState.company.removeStoredCompanyId();
      removeFilterItemsFromStorage();
      localStorageService.removeItem(autoPostingInvoiceTypeGroup);
    }
    authState.signOut();
  }, []);

  const handleClick = useCallback(
    (environmentType: EnvironmentType) => {
      removeRowsPerPage();
      earchiveState.tenant.set({
        moduleEnvironmentType: environmentType,
      });
      onClose(!openDrawer);
    },
    [onClose, openDrawer],
  );

  const accountManagementRoute = currentEnvironment
    ? AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + AppRoutes.account.address
    : AppRoutes.administration.address + '/modules/users';

  const navigate = useNavigate();

  const accountManagementClick = () => {
    if (isPhone) {
      setSubMenuOpen(!openSubMenu);
    } else {
      setOpen(false);
      navigate(accountManagementRoute);
    }
  };

  const getCustomAdminUrl = (url: string | undefined) => {
    let urlWithParams = `${url}/services`;
    if (tenantId) {
      urlWithParams += `?tenantId=${tenantId}`;
      if (companyId) {
        urlWithParams += `&companyId=${companyId}`;
      }
    }
    return urlWithParams;
  };

  const customUrl = getCustomAdminUrl(AppRoutes.administration.address);

  return (
    <div ref={ref}>
      <section className={'flex flex-col'}>
        <div className={'self-end'}>
          <ButtonTertiary
            rIcon={IconSvg.CLOSE}
            onClick={() => setOpen(false)}
            size={ButtonTertiarySize.SM}
            testId="userMenuDropdownCloseButton"
          />
        </div>
        <div className={'flex'}>
          <Avatar size={AvatarSize.MD} userName={userName} userSurname={userSurname}/>
          <div className={'flex flex-col ml-[10px] align-center'}>
            <h4 className={'font-quicksand text-xl font-medium'}>
              {userName} {userSurname}
            </h4>
            <p className={'font-quicksand text-sm font-normal'}>{authState.currentUserEmail}</p>
          </div>
        </div>
        {contextSwitcherEnabled && !isDesktop && !isLoginHub() && <ContextSwitcher/>}
      </section>
      <Separator size={SeparatorSize.MD}/>
      <div className={'flex flex-col font-quicksand text-normal font-medium'}>
        {/* Zarządzanie kontem */}
        {!isLoginHub() && accountManagementEnabled && (adminTenant || currentEnvironment) && (
          <>
            <button
              className={'flex w-full mt-[20px] mb-[10px] hover:filter-primary-400 items-center justify-between'}
              onClick={accountManagementClick}
              key={openSubMenu + 'isPhone&&openSubMenu'}
              data-testId={`accountManagementButton`}
            >
              <div className={'flex items-center'}>
                {isPhone && openSubMenu ? (
                  <Icon svg={IconSvg.ARROW_BACK_IOS} size={IconSize.SM}/>
                ) : (
                  <Icon svg={IconSvg.SETTINGS} color={IconColor.GREY_500}/>
                )}
                <p className={'ml-[10px]'}>
                  {intl.formatMessage({id: openSubMenu ? Tr.backToMainMenu : Tr.companyManagement})}
                </p>
              </div>
              {isPhone && !openSubMenu && (
                <Icon svg={IconSvg.ARROW_FORWARD_IOS} color={IconColor.GREY_500} size={IconSize.SM}/>
              )}
            </button>
            {isPhone && openSubMenu && (
              <div
                onClick={() => {
                  setSubMenuOpen(false);
                  setOpen(false);
                }}
              >
                <ConfigMenu
                  sections={availableSectionsFiltered}
                  selectedCompanyEnvironment={currentEnvironment ?? undefined}
                />
              </div>
            )}
          </>
        )}

        {!isDesktop &&
          rootTenant &&
          rootTenant?.Tenant?.Id === selectedTenantForHub?.Id &&
          moduleEnvironmentType === EnvironmentType.Company && (
            <button
              className={'flex w-full mt-[20px] mb-[10px] hover:filter-primary-400'}
              onClick={() => {
                onClose(!openDrawer);
                window.open(customUrl);
              }}
              data-testId={`switchToAdministrationButton`}
            >
              <Icon svg={IconSvg.ARROW_RIGHT_ALT} color={IconColor.GREY_500}/>
              <p className={'ml-[10px] text-left'}>{intl.formatMessage({id: Tr.modulSwitchAdmin})}</p>
            </button>
          )}
        {/* Przejdź do Symfonia KSEF */}
        {moduleEnvironmentType === EnvironmentType.Tenant && currentOrganizationIsActive && (
          <button
            className={'flex w-full mt-[20px] mb-[10px] hover:filter-primary-400'}
            onClick={() => {
              handleClick(EnvironmentType.Company);
              navigate(
                manyTenantsAndCompanies ? AppRoutes.hub.address + '/' + rootTenant?.Tenant?.Id : AppRoutes.root.address,
              );
            }}
            data-testId="switchToEArchiveButton"
          >
            <Icon svg={IconSvg.ARROW_RIGHT_ALT} color={IconColor.GREY_500}/>
            <p className={'ml-[10px]'}>{intl.formatMessage({id: Tr.modulSwitchEarchive})}</p>
          </button>
        )}
        <button
          className={'flex w-full mt-[20px] mb-[10px] hover:filter-primary-400'}
          onClick={handleLogout}
          data-testId="logoutButton"
        >
          <Icon svg={IconSvg.EXIT_TO_APP} color={IconColor.GREY_500}/>
          <p className={'ml-[10px]'}>{intl.formatMessage({id: Tr.logout})}</p>
        </button>
      </div>
      {addTenantEnabled && <AddTenantButton/>}
    </div>
  );
});
