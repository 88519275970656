/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {FC, useEffect} from 'react';
import AuthenticationOption from './AuthenticationOption';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import SzafirSdkError from './SzafirSdkError';
import {SzafirService, useSzafirSdk} from '@symfonia/szafir-react';
import {AuthType} from '../../../../../state/AuthenticationState/AuthenticationState';
import {ToastVariant} from '@symfonia/brandbook';
import {
  authenticationState,
  authorizationInKSeFService,
  authorizationInKSeFWithTokenService,
  earchiveState,
  removeAuthorizationInKSeFService,
} from '@symfonia-ksef/state/rootRepository';

export interface AuthenticationSignatureProps {
  isAuth?: boolean;
  onAuthorized: (companyId: string, szafirService?: SzafirService) => Promise<void>;
}

export const AuthenticationSignature: FC<AuthenticationSignatureProps> = observer(
  ({
     onAuthorized,
     isAuth,
   }) => {
    const {szafirSdkError, szafirService, setSzafirSdkError} = useSzafirSdk();
    const addAlert = earchiveState.alertsState.addAlert;

    const {
      company: {companyId},
      packageStatistics,
    } = earchiveState;

    const {
      removeAuthorizationRunner: {isPending: removingIsPendingTokenRunner, loading: removingLoadingTokenRunner},
      authorizationRunner: {isPending: isPendingTokenRunner, loading: loadingTokenRunner},
    } = authorizationInKSeFWithTokenService;

    const activeEvent = authorizationInKSeFService.isPending || removeAuthorizationInKSeFService.isPending;
    const oppositeEventRunning = isPendingTokenRunner || removingIsPendingTokenRunner || removingLoadingTokenRunner || removingLoadingTokenRunner || loadingTokenRunner;
    const {loadingToken, loadingSignature: loading, authType} = authenticationState;

    useEffect(() => {
      const getErrorMessage = () => {
        if (szafirSdkError) {
          return <SzafirSdkError szafirSdkError={szafirSdkError} szafirService={szafirService}/>;
        }
        return null;
      };

      const error = getErrorMessage();
      if (error) {
        authenticationState.setLoading(false);
        authenticationState.setAuthType(undefined);
        addAlert(error, ToastVariant.ERROR, {displayDuration: 0, omitIfHasTheSameAlert: true});
        if (setSzafirSdkError) {
          setSzafirSdkError(undefined);
        }
      }
    }, [addAlert, szafirSdkError, szafirService, setSzafirSdkError]);


    function checkIsEnable() {
      if (isAuth) {
        return true;
      }
      return packageStatistics.subscriptionIsActive;
    }

    return (
      <AuthenticationOption
        titleMessageId={Tr.szafirHeader}
        descMessageId={Tr.szafirContent}
        showLoader={(loading || activeEvent) && !szafirSdkError}
        disabled={oppositeEventRunning || loading || activeEvent || !checkIsEnable() || (authType === AuthType.Token && (loadingToken || authorizationInKSeFWithTokenService.authorizationRunner.isPending))}
        processMessage={authenticationState.processMessage}
        clicked={() => companyId && onAuthorized(companyId, szafirService)}
      />
    );
  });

