import React, {FC, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Dropdown, DropdownSize, DropdownWidth} from '@symfonia/brandbook';
import {HubOrganizationSelectStateI} from '../storage/HubOrganizationSelectState';


export const OrganizationsSelect: FC<{ service: HubOrganizationSelectStateI, className?: string }> = observer(({
                                                                                                                 service,
                                                                                                                 className,
                                                                                                               }) => {
  const {currentOrganizationToDisplay, organizationsToDisplay} = service;
  const selectedOrganizationValue = useMemo(() => currentOrganizationToDisplay?.value ? [currentOrganizationToDisplay.value] : undefined, [currentOrganizationToDisplay?.value]);

  return <div className={className}>
    <Dropdown
      width={DropdownWidth.FULL}
      size={DropdownSize.SM}
      options={organizationsToDisplay}
      value={selectedOrganizationValue}
      onChange={([value]) => service.setSelectedOrganization(value)}
      testId="organizationSelectHub"
    />
  </div>;
});
