import {observer} from 'mobx-react-lite';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Tr} from '@symfonia-ksef/locales/keys';
import React, {useCallback, useEffect, useRef} from 'react';
import './ocrPage.css';
import {OCR_URL} from '../../../../environments/environment.common';
import {PageContent} from '@symfonia/symfonia-ksef-components';
import {authState, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {Breadcrumbs} from '@symfonia/brandbook';
import {Content} from '../../../root/components/Content';
import {AppRoutes} from '../../../root/Routes';

export enum OcrScope {
  import = 'import',
  list = 'list'
}

enum OcrEvents {
  DOCUMENT_VERIFIED = 'DOCUMENT_VERIFIED',
  LOGOUT = 'LOGOUT'
}

const getIframeOcrUrls = (scope: OcrScope, companyId: string): string => `${OCR_URL}/external/module-ocr/${scope}?b2c=true&companyId=${companyId}`;

export const OcrPage = observer(() => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const {scope, companyId} = useParams<{ scope: OcrScope, companyId: string }>();

  const {packageStatistics, company: {autoPosting}} = earchiveState;

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.companiesList}),
      onClick: () => {
        earchiveState.company.removeStoredCompanyId();
        navigate(AppRoutes.hub.address);
      } 
    },
    {
      label: 'KSeF',
      onClick: () => navigate(AppRoutes.eArchive.address)
    },
    {
      label: intl.formatMessage({id: Tr.addFilesForOcrUpload}),
    },
  ];

  const redirectToEArchive = useCallback(() => {
    navigate(location.state?.from ?? '../documents');
  }, [location.state?.from]);

  useEffect(() => {
    if (!scope || !Object.values(OcrScope).includes(scope)) {
      navigate(`../ocr/import`);
    }
  }, [scope]);

  useEffect(() => {
    if (!autoPosting?.Status || packageStatistics.isPackageNotActive) {
      redirectToEArchive();
    }
  }, [redirectToEArchive, autoPosting?.Status, packageStatistics.isPackageNotActive]);

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      try {
        const data = JSON.parse(e.data) as { document_id: number, event: OcrEvents };
        switch (data.event) {
          case OcrEvents.DOCUMENT_VERIFIED: {
            console.log('document verified...');
            return;
          }
          case OcrEvents.LOGOUT: {
            console.log('logout...');
            authState.signOut();
          }
        }
      } catch (err) {
        console.error(err);
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);

  const win = (window as any);
  useEffect(() => {
    win.zE.hide();
    return () => {
      win.zE.show();
    };
  }, []);

  return (
    <Content className="h-screen">
    <div className="ocr-content-wrapper">
      <Breadcrumbs breadcrumbs={breadcrumbs} testId="ocrPage-breadcrumbs"/>
      <PageContent paddingDisabled className="ocr-content h-full mt-2">
        <iframe ref={iframeRef}
                src={getIframeOcrUrls(scope ?? OcrScope.import, companyId ?? '')}
                className="ocr-iframe"
                width="100%"
        />
    </PageContent>
    </div>
    </Content>
  );
});
