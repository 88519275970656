import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';
import {Tr} from '../../../../locales/translationKeys';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {InvoicesUploader} from '../../components/FilesUploader/InvoicesUploader';
import {Content} from '../../../root/components/Content';
import {Breadcrumb, Breadcrumbs} from '@symfonia/brandbook';
import {AppRoutes} from '../../../root/Routes';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';


export const UploadXML = observer(() => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToEArchive = () => {
    navigate(location.state?.from ?? '../documents');
  };

  const breadcrumbs: Breadcrumb[] = [
    {
      label: intl.formatMessage({id: Tr.companiesList}),
      onClick: () => {
        earchiveState.company.removeStoredCompanyId();
        navigate(AppRoutes.hub.address);
      } 
    },
    {
      label: 'KSeF',
      onClick: redirectToEArchive,
    },
    {
      label: intl.formatMessage({id: Tr.uploadFilesWindowHeader}),
    },
  ];


  return <Content>
    <Breadcrumbs breadcrumbs={breadcrumbs} className="mb-[8px]"/>
    <InvoicesUploader/>
  </Content>;
});
