import {ContextualMenu} from '../../common/components/ContextualMenu/ContextualMenu';
import {IconSvg} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {MultipleActionsRequestTypeEnum} from '../../earchive/pages/Documents/state/FilehubState';

type Props = {
  state: IFilehubState;
};

const FilehubTableMenu = observer(({state}: Props) => {
  const closeActionMenu = () => {
    state.setSelectedInvoiceMenuAnchorEl(null);
    state.setSelectedInvoiceMenuOpened(false);
  };

  const attachments = state.attachments;
  const isOpen = state.selectedAttachmentMenuOpened;
  const anchorEl = state.selectedAttachmentMenuAnchorEl;
  const id = state.selectedRow?.Id;
  const selectedName = state.selectedRow?.FileName;
  const isPublic = state.selectedRow?.IsPublic;

  const {downloadAttachmentAction} = useFilehubMethods(state);

  const changeStatus = (changeToPublic: boolean, id: string) => {
    const newAttachmentsList = attachments.map(attachment => {
      if (attachment.Id === id) {
        attachment.IsPublic = changeToPublic;

        return {
          ...attachment,
          IsPublic: changeToPublic,
        };
      }
      return attachment;
    });

    state.setAttachments(newAttachmentsList);
    state.setMultipleActionsRequestType(
      changeToPublic ? MultipleActionsRequestTypeEnum.Public : MultipleActionsRequestTypeEnum.Private,
    );
    state.setAreFooterButtonsShown(true);
    state.setSelectedInvoiceMenuOpened(false);
  };

  const downloadAttachment = async () => {
    state.setDownloadedAttachment(selectedName!);
    await downloadAttachmentAction(id);
  };

  const actions = [
    {
      icon: IconSvg.GET_APP,
      key: Tr.download,
      label: intl.formatMessage({id: Tr.download}),
      onClick: () => {
        closeActionMenu();
        downloadAttachment();
      },
    },
    {
      hidden: isPublic,
      icon: IconSvg.PUBLIC,
      key: Tr.changeToPublic,
      label: intl.formatMessage({id: Tr.changeToPublic}),
      onClick: () => changeStatus(true, id),
    },
    {
      hidden: !isPublic,
      icon: IconSvg.VPN_LOCK,
      key: Tr.changeToPrivate,
      label: intl.formatMessage({id: Tr.changeToPrivate}),
      onClick: () => changeStatus(false, id),
    },
    {
      icon: IconSvg.DELETE_OUTLINE,
      key: Tr.removeButton,
      label: intl.formatMessage({id: Tr.removeButton}),
      onClick: () => {
        closeActionMenu();
        state.setIsDeleteAttachmentModalOpen(true);
        state.setDeleteAttachmentsIdsList([state.selectedRow?.Id]);
        state.setDeleteAttachmentsList([state.selectedRow!]);
      },
    },
  ];

  return <ContextualMenu anchorEl={anchorEl} onClose={closeActionMenu} isOpen={isOpen} actions={actions}/>;
});

export default FilehubTableMenu;
