import {observer} from 'mobx-react-lite';
import {MissingInvoicesModal} from './MissingInvoices';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {getUpoResultService} from '@symfonia-ksef/state/rootRepository';

export const UPOMissedInvoicesModal = observer(() => {
  const intl = useIntl();

  return <MissingInvoicesModal
    title={intl.formatMessage({id: Tr.missingUpoTitle})}
    content={
      <FormattedMessage
        id={Tr.missingUpoContent}
        values={
          {
            br: <br/>,
            from: getUpoResultService.uniqueMissingInvoicesCount,
            to: getUpoResultService.requestedInvoicesCount,
          }
        }
      />
    }
    missingInvoices={getUpoResultService.missingItems}
    requestedInvoicesCount={getUpoResultService.requestedInvoicesCount}
    open={getUpoResultService.modalIsActive}
    onClose={() => getUpoResultService.setModalIsActive(false)}
    tableHeaders={[intl.formatMessage({id: Tr.InvoiceNumber}), intl.formatMessage({id: Tr.message})]}
  />;
});
