import React, {FC, useState} from 'react';
import {useForm, useFormState, Controller} from 'react-hook-form';

import {ACCOUNT_MANAGEMENT_CONSTS} from './consts';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Button from '@mui/material/Button/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {AppRoutes} from '../../../../root/Routes';
import {SubscriptionType, useSetSubscriptionTenantMutation} from '@symfonia-ksef/graphql';
import {getGraphqlBackendError} from '../../../../common/helpers/packageStatisticsHelper';
import {observer} from 'mobx-react-lite';
import {
  PackageStatisticsRepositoryInterface,
} from '../../../../../state/PackageStatisticsState/PackageStatisticsRepository';
import {usePackageStatisticHandlers} from '../../../hooks/usePackageStatisticLoader';
import {ButtonSecondary, IconColor, IconSize, IconSvg, Input, InputSize, InputWidth, ToastVariant, TooltipPosition} from '@symfonia/brandbook';
import {DialogModal, IconWithTooltip, LoadingButton} from '@symfonia/symfonia-ksef-components';
import {Text} from '../../../../root/components/Text';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

const SubscriptionValidationSchema = Yup.object().shape({
  SerialNumber: Yup.string()
    .max(50, 'Numer nie może przekroczyć 50 znaków')
    .matches(/^[a-zA-Z]{3}-[1-9]{1}[0-9]{5}$/, 'Nieprawidłowy numer seryjny'),
});

type SubscriptionFormModel = {
  SubscriptionNumber?: string | undefined;
  SerialNumber?: string | undefined;
};

type SubscriptionFormType = {
  isAdmin: boolean;
  packageSubscriptionRepository: PackageStatisticsRepositoryInterface;
};

const SubscriptionFormContainer: FC<SubscriptionFormType> = observer(({packageSubscriptionRepository, isAdmin}) => {
  const intl = useIntl();
  const inputSize = InputSize.SM;
  const inputWidth = InputWidth.FULL;

  const serialNumberIntl = intl.formatMessage({id: Tr.serialNumber});
  const subscriptionNumberIntl = intl.formatMessage({id: Tr.subscriptionNumber});

  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [setSubscriptionTenant] = useSetSubscriptionTenantMutation({context: {envId: earchiveState.company.tenantId}});
  const metaData = ACCOUNT_MANAGEMENT_CONSTS.SUBSCRIPTION_METADATA;
  const addAlert = earchiveState.alertsState.addAlert;
  const {
    subscriptionType,
    state: {SubscriptionNumber, SerialNumber},
  } = packageSubscriptionRepository;
  const {onLoad, mapErrorMessage} = usePackageStatisticHandlers();
  const isFreemium = subscriptionType === SubscriptionType.Freemium;
  const isSerialNumberEnabled = isFreemium && !SerialNumber;
    const {control, setError, getValues, reset} = useForm<SubscriptionFormModel>({
    defaultValues: {SubscriptionNumber: SubscriptionNumber ?? '', SerialNumber: SerialNumber ?? ''},
    mode: 'onChange',
    resolver: yupResolver(SubscriptionValidationSchema),
  });
  const {isValid, isDirty, errors} = useFormState({control});

  const handleConfirm = async () => {
    const serialNumber = getValues('SerialNumber')?.trim();
    if (!serialNumber) {
      return;
    }
    setLoading(true);

    await packageSubscriptionRepository.sendSerialNumber(
      serialNumber,
      {
        onError: errors => {
          const errorMessage = getGraphqlBackendError(errors);
          if (errorMessage) {
            setTitle(Tr.serialNumberWrongModal_Header);
            setContent(errorMessage);
            setOpenModal(true);
            setError('SerialNumber', {message: errorMessage, type: 'custom'}, {shouldFocus: true});
          } else {
            errorMessage && addAlert(errorMessage, ToastVariant.ERROR);
            setOpenModal(false);
          }
        },
        onSend: async packageStatistic => {
          await packageStatistic.load(
            {onLoad, mapErrorMessage},
          );
        },
      },
    );

    setLoading(false);
  };

  function cancelSubscription() {
    reset({SerialNumber: ''});
  }

  return (
    <div className="grid">
      <DialogModal
        onCancel={() => setOpenModal(false)}
        open={openModal}
        okButton={{text: intl.formatMessage({id: Tr.acknowledge}), onClick: () => setOpenModal(false)}}
        title={title ? intl.formatMessage({id: title}) : ''}
        centerButtons
      >
        {content && (
          <FormattedMessage
            id={Tr.serialNumberGenericMessage}
            values={{
              message: content,
              link: (
                <a className="font-bold text-primary-500 underline" href={AppRoutes.eBok.address} rel="noreferrer" target="_blank">
                  {intl.formatMessage({id: Tr.eBok})}
                </a>
              ),
            }}
          />
        )}
      </DialogModal>

      <div className="flex flex-col">
        <Text className="font-bold my-[20px]">{metaData.titleLabel}</Text>
        <div className="grid gap-[18px]">
          <Controller
            name="SubscriptionNumber"
            control={control}
            render={({field}) => (
              <Input
                className="max-w-[280px]"
                disabled={loading || !isAdmin || !!SerialNumber || isFreemium}
                label={subscriptionNumberIntl}
                placeholder={subscriptionNumberIntl}
                size={inputSize}
                value={SubscriptionNumber ?? ''}
                width={inputWidth}
                onChange={val => field.onChange(val)}
                testId="subscriptionInfoSubscriptionNumber"
            />)}
          />

          <div className="flex items-center w-full">
            <div className="flex-auto max-w-[280px]">
              <Controller
                name="SerialNumber"
                control={control}
                render={({field}) => (
                  <Input
                    disabled={loading || !isAdmin || !isSerialNumberEnabled}
                    label={
                      <div className="flex items-center mb-[8px]">
                      <Text className="text-sm text-grey-400">
                        <FormattedMessage id={serialNumberIntl}/>
                      </Text>
                      <IconWithTooltip
                        tooltipWidth="auto"
                        tooltipClass={'w-[800px]'}
                        icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
                        size={IconSize.MD}
                        btnClass={'flex items-center ml-[4px]'}
                        tooltipPosition={TooltipPosition.RIGHT}
                        tooltipContent={<>
                          <h1 className={'mb-[10px] font-bold'}>{intl.formatMessage({id: Tr.serialNumberTooltip_Header})}</h1>
                          <p>{intl.formatMessage({id: Tr.serialNumberTooltip_Content}, {br: <br/>})}</p>
                        </>
                        }
                      />
                    </div>
                    }
                    placeholder={serialNumberIntl}
                    size={inputSize}
                    value={field.value}
                    width={inputWidth}
                    onInput={val => field.onChange(val)}
                    testId="subscriptionInfoSerialNumber"
                />)}
              />
              {errors.SerialNumber && <Text className="text-sm text-red-500">{errors.SerialNumber.message}</Text>}
            </div>
          </div>
          {!SerialNumber && isAdmin && (
            <div className="flex justify-start items-center gap-4 w-full">
              <ButtonSecondary testId="cancelSubscriptionButton" onClick={cancelSubscription} text={intl.formatMessage({id: Tr.cancelButton})} disabled={loading || !isDirty}/>
              <LoadingButton
                disabled={!isValid || !isDirty}
                loading={loading}
                className="btn"
                onClick={handleConfirm}
                text={intl.formatMessage({id: Tr.save})}
                testId="sendSerialNumberButton"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default SubscriptionFormContainer;
