import {action, computed, makeObservable, observable} from 'mobx';
import {CompanyAssigmentListItemFragment, IdentifierType, TenantStatus} from '@symfonia-ksef/graphql';
import {DropdownListOption} from '@symfonia/brandbook';
import {CompaniesRepositoryI} from '@symfonia-ksef/state/CompaniesRepository/CompaniesRepository';
import {BaseModule, BaseModuleI} from '../../../services/MobXServices/BaseModule';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export type Organization = {
  Id: any, Name?: string | undefined,
  Identifier?: string | undefined,
  IdentifierType: IdentifierType,
  Status: TenantStatus
}

export interface HubOrganizationSelectStateI extends BaseModuleI {
  get organizations(): Organization[];

  get selectedOrganization(): Organization | null;

  get currentOrganizationToDisplay(): DropdownListOption | undefined;

  get organizationsToDisplay(): DropdownListOption[];

  get currentCompanies(): CompanyAssigmentListItemFragment[];

  setSelectedOrganization(id: string): void;
}

export class HubOrganizationSelectState extends BaseModule implements HubOrganizationSelectStateI {

  @observable
  private _selectedOrganizationId!: string | null;

  constructor(private readonly repository: CompaniesRepositoryI, tenantId: string) {
    super();
    this.setSelectedOrganization(tenantId);
    this.reactionsManager.add(() => this._selectedOrganizationId, (currentTenantId, prevTenantId) => {
      if (currentTenantId === prevTenantId || !currentTenantId) {
        return;
      }
      this.repository.configure({TenantIdFilter: currentTenantId}).fetch();
    });
    makeObservable(this);
  }


  @computed
  public get organizations(): Organization[] {
    return earchiveState.environments.tenants.filter(el => el.Status === TenantStatus.Active);
  }

  @computed
  public get selectedOrganization(): Organization | null {
    return this.organizations.find((org) => this._selectedOrganizationId === org.Id) ?? null;
  }

  @computed
  public get currentOrganizationToDisplay(): DropdownListOption | undefined {
    return this.selectedOrganization ? {
      label: this.selectedOrganization.Name ?? '',
      value: this.selectedOrganization.Id,
    } : undefined;
  }

  @computed
  public get organizationsToDisplay(): DropdownListOption[] {
    return this.organizations.filter(org => org.Name && org.Id).map(org => ({
      label: org.Name as string,
      value: org.Id,
    }));
  }

  @computed
  public get currentCompanies(): CompanyAssigmentListItemFragment[] {
    return this._selectedOrganizationId ? this.repository.getCompaniesByTenantId(this._selectedOrganizationId) : [];
  }

  @action.bound
  public setSelectedOrganization(id: string): void {
    this._selectedOrganizationId = id;
    earchiveState.tenant.setRootTenant({tenantId: id}, earchiveState.environments);
  }
}
