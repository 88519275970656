import {useEffect, useState} from 'react';
import {useCopyToClipboard} from 'usehooks-ts';
import {ButtonTertiary, IconColor, IconSize, IconSvg, IconVariant} from '@symfonia/brandbook';

interface CopyToClipboardButtonProps {
  onClick: (id?: string) => string;
  variant?: IconVariant;
  size?: IconSize;
  id?: string;
  className?: string;
}

export const CopyToClipboardButton = ({onClick, id, variant = IconVariant.CONTOUR, size = IconSize.MD, className}: CopyToClipboardButtonProps) => {
  const [copiedValue, copy] = useCopyToClipboard();
  const [disabled, setDisabled] = useState<boolean>();
  const handleClick = () => copy(onClick(id));

  useEffect(() => {
    setDisabled(!onClick(id));
  }, [id, onClick]);

  return (
    <ButtonTertiary
      onClick={handleClick}
      lIcon={IconSvg.FILE_COPY}
      iconOptions={{size: size, variant: variant, color: disabled ? IconColor.GREY_400 : IconColor.GREEN_600}}
      className={className}
      testId="copyToClickboard"
    />
  );
};
