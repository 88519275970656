import {State} from '../../modules/root/services/MobXServices/State';
import {InvoiceBound, InvoiceDto} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {InvoiceDownloadModalProps} from '../../modules/earchive/components/InvoicesTable/Components/InvoiceDownloadedModal';

export type InvoicesContextStateT = {
  invoiceBound: InvoiceBound,
  isPostingPartlyNotAvailableModalOpen: boolean,
  postingUnavailableInvoices: InvoiceDto[],
  isPublishInvoicesModalOpen: boolean,
  isSentToKsefInvoicesModalOpen: boolean,
  isInvoicesWithoutDecreeModalOpen: boolean,
  selectedInvoices: InvoiceDto[],
  setInvoiceDownloadedModalProps?: InvoiceDownloadModalProps,
}

export class InvoicesContextState extends State<InvoicesContextStateT> {
  constructor() {
    super({
      invoiceBound: InvoiceBound.External,
      isPostingPartlyNotAvailableModalOpen: false,
      postingUnavailableInvoices: [],
      isPublishInvoicesModalOpen: false,
      isSentToKsefInvoicesModalOpen: false,
      isInvoicesWithoutDecreeModalOpen: false,
      selectedInvoices: [],
      setInvoiceDownloadedModalProps: undefined,
    });
    makeObservable(this);
  }

  @computed
  public get isExternal(): boolean {
    return this.state.invoiceBound === InvoiceBound.External;
  }

  @computed
  public get isInternal(): boolean {
    return this.state.invoiceBound === InvoiceBound.Internal;
  }
}
