import {FC, PropsWithChildren} from 'react';
import {Popover} from '@mui/material';

export type MenuOption = {
  renderComponent: () => React.ReactNode;
  hidden: boolean;
};

export type MenuOptions = MenuOption[];

export type MenuProps = {
  options: MenuOptions;
  onClose?: () => void;
  isOpen: boolean;
  anchorEl?: HTMLElement | null;
  className?: string | undefined;
  testId?: string;
};
export const Menu: FC<PropsWithChildren<MenuProps>> = ({
                                                         options,
                                                         onClose = () => undefined,
                                                         isOpen = false,
                                                         anchorEl = null,
                                                         className = undefined,
                                                         testId
                                                       }) => {
  return <Popover
    open={isOpen}
    onClose={onClose}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    PaperProps={{
      style: {
        width: 'fit-content',
        background: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <div
      data-testid={testId}
      className={`${className} flex bg-white border rounded-lg font-quicksand flex-col border-grey-300 shadow overflow-x-hidden`}>
      {options
        .filter((option) => option.hidden === false)
        .map((option) => option.renderComponent())}
    </div>
  </Popover>;
};
