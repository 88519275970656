import {Icon, IconColor, IconSize, IconSvg, Tooltip} from '@symfonia/brandbook';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';

export enum FilehubAttachmentStatus {
  Public = 'Public',
  Private = 'Private',
}

export const filehubTableConfig = (
  handleHeaderCheckboxSelect: (checked: boolean) => void,
  allSelectedAttachments: boolean,
) => [
  {
    name: 'checkbox',
    checkbox: {
      name: 'checkbox-1',
      value: 'checkbox-1',
      checked: allSelectedAttachments,
      onChange: (checked: boolean) => {
        handleHeaderCheckboxSelect(checked);
      },
    },
    width: 'w-[20px]',
  },
  {
    name: Tr.invoiceName,
    title: intl.formatMessage({id: Tr.invoiceName}),
    width: 'w-[230px]',
  },
  {
    name: Tr.dateOfAdding,
    title: intl.formatMessage({id: Tr.dateOfAdding}),
    width: 'w-[90px]',
  },
  {
    name: Tr.addedBy,
    title: intl.formatMessage({id: Tr.addedBy}),
    width: 'w-[240px]',
  },
  {
    content: (
      <>
        {intl.formatMessage({id: Tr.status})}{' '}
        <Tooltip text={intl.formatMessage({id: Tr.filehubStatusTooltipText}, {br: <br/>})}>
          <Icon className="ml-[8px]" color={IconColor.BLUE1_400} size={IconSize.MD} svg={IconSvg.INFO}/>
        </Tooltip>
      </>
    ),
    name: Tr.status,
    width: 'w-[120px]',
  },
  {
    name: Tr.size,
    title: intl.formatMessage({id: Tr.size}),
    width: 'w-[65px]',
  },
  {
    name: Tr.actions,
    title: intl.formatMessage({id: Tr.actions}),
    width: 'w-[50px]',
  },
];
