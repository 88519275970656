import {
  EnvironmentListItemFragment,
  EnvironmentStatus,
  EnvironmentType,
  MyEnviromentsDocument,
  MyEnviromentsQuery,
  MyEnviromentsQueryVariables,
  TenantStatus,
} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';

import {StoreEvents, StoreMediator} from './EarchiveState';
import {notEmpty} from '../../modules/common/helpers/helperFunctions';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {BaseRepository} from '../../modules/root/services/MobXServices/Repository/BaseRepository';

export class EnvironmentsState extends BaseRepository<keyof MyEnviromentsQuery, MyEnviromentsQuery, MyEnviromentsQueryVariables> {

  constructor(private globalState: StoreMediator) {
    super('MyEnvironments', MyEnviromentsDocument);
    this.setEnvId('');
    makeObservable(this);
  }

  @computed
  public get environments(): EnvironmentListItemFragment[] {
    if (!this.data?.Environments) {
      return [];
    }
    return this.data.Environments.filter(notEmpty).sort((a, b) => (a.Name !== undefined && b.Name !== undefined) ? a.Name.localeCompare(b.Name) : 0);
  }

  @computed
  public get tenants() {
    const tempTenants = this.environments.filter(el => el.Type === EnvironmentType.Company).flatMap(el => el.Tenant).filter(notEmpty);
    return [...new Map(tempTenants.map(item => [item?.Id, item])).values()].sort((a, b) => (a.Name !== undefined && b.Name !== undefined) ? a.Name.localeCompare(b.Name) : 0);
  }

  @computed
  public get companies(): EnvironmentListItemFragment[] {
    return this.environments.filter(el => el.Type === EnvironmentType.Company);
  }

  @computed
  public get activeTenants() {
    return this.tenants.filter(tenant => tenant.Status === TenantStatus.Active);
  }

  @computed
  public get activeCompanies() {
    return this.companies.filter(company => company.Status === EnvironmentStatus.Active);
  }

  @computed
  public get firstActiveEnvironment() {
    return this.activeCompanies[0];
  }

  @computed
  public get adminTenant(): EnvironmentListItemFragment | undefined {
    return this.environments.find(env => env.Type === EnvironmentType.Admin);
  }

  public getEnvironmentById(id: string) {
    return this.environments.find(env => env.EnvironmentId === id);
  }

  public getFirstEnvironmentByType(type?: EnvironmentType) {
    if (!type) {
      return this.environments[0];
    }
    return this.environments.filter(env => env.Type === type && env.Status === EnvironmentStatus.Active)[0];
  }

  public override checkIsReady(): boolean {
    return true;
  }

  protected async _onMount(): Promise<void> {
    await this.fetch();
  }

  protected onSuccess(data: MyEnviromentsQuery[keyof MyEnviromentsQuery]): void | Promise<void> {
    this.globalState.notify(this, StoreEvents.EnvironmentsLoaded);
  }

  protected onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    console.log(error);
    return super.onError(errors, error);
  }
}
