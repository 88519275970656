import {FC} from 'react';
import {Icon, IconColor, IconSize, IconSvg, IconVariant} from '@symfonia/brandbook';

export enum WarningIconType {
  NoAccounts,
  Validated,
  Warn,
  Error
}

export type WarningIconProps = { type?: WarningIconType, hidden?: boolean }

export const WarningIcon: FC<WarningIconProps> = ({type = WarningIconType.Warn, hidden}) => {

  if (hidden) {
    return null;
  }

  if (type === WarningIconType.Validated) {
    return (
      <Icon
        svg={IconSvg.CHECKMARK_CIRCLE}
        color={IconColor.GREEN_500}
        size={IconSize.MD}
        variant={IconVariant.CONTOUR}
      />
    );
  }

  if (type === WarningIconType.NoAccounts) {
    return (
      <Icon
        svg={IconSvg.INFO}
        color={IconColor.BLUE1_500}
        size={IconSize.MD}
        variant={IconVariant.CONTOUR}
      />
    );
  }

  if (type === WarningIconType.Warn) {
    return (
      <Icon
        svg={IconSvg.INFO}
        color={IconColor.ORANGE_500}
        size={IconSize.MD}
        variant={IconVariant.CONTOUR}
      />
    );
  }

  return (
    <Icon
      svg={IconSvg.WARNING_CIRCLE}
      color={IconColor.RED_500}
      size={IconSize.MD}
      variant={IconVariant.CONTOUR}
    />
  );
};
