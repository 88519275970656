import {FC, ReactNode, useState} from 'react';
import {SzafirSdk, SzafirSdkNoExtensionErrorCode, SzafirSdkNoExtensionErrorInfo, SzafirSdkNoHostAppErrorCode, SzafirSdkNoHostAppErrorInfo} from '../models';
import {SzafirService} from '../services';
import {SzafirSdkContextProvider, initialContextState, SzafirSdkError} from './SzafirSdkContextProvider';

declare global {
  interface Window {
    SZAFIR_SDK: SzafirSdk;
  }
}

export interface SzafirSdkProviderProps {
  debug: boolean;
  webModuleLocationUrl: string;
  sdkLocationUrl: string;
  versionsFile: string;
  children: ReactNode;
}

export const SzafirSdkProvider: FC<SzafirSdkProviderProps> = (props: SzafirSdkProviderProps) => {
  const szafirSdk = window.SZAFIR_SDK;
  if (!szafirSdk) {
    throw new Error(`Module 'SZAFIR_SDK' not found in the window object. Make sure you have linked the SDK library correctly.`);
  }

  const szafirService = new SzafirService(szafirSdk);
  const [szafirSdkError, setSzafirSdkError] = useState<SzafirSdkError | undefined>(initialContextState.szafirSdkError);

  const handleUnexpectedError = (error: string) => {
    logError(error);
    setSzafirSdkError('Unexpected');
  };

  const handleNoExtensionError = (error: SzafirSdkNoExtensionErrorCode, info: SzafirSdkNoExtensionErrorInfo) => {
    logError(error, info);
    setSzafirSdkError('NoExtension');
  };

  const handleNoHostAppError = (error: SzafirSdkNoHostAppErrorCode, info: SzafirSdkNoHostAppErrorInfo) => {
    logError(error, info);
    if (error === 'NOT_UPDATED') {
      setSzafirSdkError('HostAppOutdated');
      return;
    }

    setSzafirSdkError('NoHostApp');
  };

  const logError = (error: string, info?: any) => {
    if (!props.debug) {
      return;
    }

    console.error('SzafirSdkProvider: ', error, info);
  };

  szafirSdk.config({
    debug: props.debug,
    webmodule_location_url: props.webModuleLocationUrl,
    sdk_location_url: props.sdkLocationUrl,
    versions_file: props.versionsFile,
    onError: handleUnexpectedError,
    onErrorNoExtension: handleNoExtensionError,
    onErrorNoHostApp: handleNoHostAppError
  });

  return (
    <SzafirSdkContextProvider.Provider value={{szafirSdkError, szafirService, setSzafirSdkError}}>
      {props.children}
    </SzafirSdkContextProvider.Provider>
  );
};
