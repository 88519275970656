import {
  CurrentEnvironmentDocument,
  CurrentEnvironmentItemFragment,
  CurrentEnvironmentQuery,
  CurrentEnvironmentQueryVariables,
  CurrentEnvironmentTenantItemFragment,
  KSeFAuthResult,
  KSeFTokenCredential,
  KSeFUserPropertiesType,
} from '@symfonia-ksef/graphql';
import {computed, makeObservable} from 'mobx';
import {StoreEvents, StoreMediator} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {AutoFetchingInvoicesModel} from '../../modules/earchive/models/AutoFetchingInvoicesModel';
import {localStorageService} from '../../modules/common/helpers/storage';
import {AutoSendingInvoicesModel} from '../../modules/earchive/models/AutoSendingInvoicesModel';
import {backgroundActionsService} from '@symfonia-ksef/state/rootRepository';
import {CompanyEnvironmentModel} from '../../modules/earchive/models';
import {BaseRepository} from '../../modules/root/services/MobXServices/Repository/BaseRepository';
import {EnvObserver, EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {addAlert} from '../../services/helpers/AlertService';


const SAVED_COMPANY_ID_KEY = 'symfonia.earchive.saved-company-id';

export class CompanyState extends BaseRepository<'CurrentEnvironment', CurrentEnvironmentQuery, CurrentEnvironmentQueryVariables> {

  public readonly envObserver!: EnvObserverI;

  constructor(private globalState: StoreMediator) {
    super('CurrentEnvironment', CurrentEnvironmentDocument);
    this.envObserver = new EnvObserver(this);
    makeObservable(this);
  }

  @computed
  public get userDetails() {
    return {
      FirstName: this.currentEnvironment?.FirstName,
      LastName: this.currentEnvironment?.LastName,
      Email: this.currentEnvironment?.Email,
    };
  }

  @computed
  public get companyId(): string | null {
    return this.data?.EnvironmentId ?? null;
  }

  @computed
  public get identifier(): string | null {
    return this.currentEnvironment?.Company?.Identifier ?? null;
  }

  @computed
  public get authorizedInKSeF(): KSeFAuthResult | undefined {
    return this.data?.UserProperties?.KSeF?.Authorized;
  }

  @computed
  public get userId(): string | null {
    return this.data?.UserId ?? null;
  }

  @computed
  public get ksefCredentials(): KSeFTokenCredential[] {
    return this.data?.UserProperties?.KSeF?.Credentials ?? [];
  }

  @computed
  public get ksefAuthorizedData(): KSeFUserPropertiesType | undefined {
    return this.data?.UserProperties?.KSeF;
  }

  @computed
  public get autoFetchingInvoicesConfig() {
    return this.currentEnvironment?.UserProperties?.EArchive?.AutoFetchingInvoices;
  }

  @computed
  public get autoSendingInvoicesConfig() {
    return this.currentEnvironment?.UserProperties?.EArchive?.AutoSendingInvoices;
  }

  @computed
  public get sharedAccessConfig() {
    return this.currentEnvironment?.UserProperties?.EArchive?.SharedAccess;
  }

  @computed
  public get whiteListConfig() {
    return this.currentEnvironment?.UserProperties?.EArchive?.WhiteListValidation;
  }

  @computed
  public get selectedTenantForHub(): CurrentEnvironmentTenantItemFragment | null {
    return this.data?.Tenant ?? null;
  }

  @computed
  public get tenantId(): string | null {
    return this.data?.Tenant?.Id ?? null;
  }

  @computed
  public get autoPosting() {
    return this.currentEnvironment?.UserProperties?.AutoPosting;
  }

  @computed
  public get currentEnvironment(): CompanyEnvironmentModel | null {
    return this.data as CompanyEnvironmentModel | null;
  }

  @computed
  public get name(): string | null {
    return this.currentEnvironment?.Company?.Name ?? null;
  }

  public updateAutoFetchingConfig(autoFetchingConfig: AutoFetchingInvoicesModel): void {
    this.set(currentData => {
      if (currentData?.UserProperties?.EArchive?.AutoFetchingInvoices) {
        currentData.UserProperties.EArchive.AutoFetchingInvoices = autoFetchingConfig;
      }
    });
  }

  public updateAuthorizationInKsef(authorization?: KSeFAuthResult): void {
    this.set(currentData => {
      if (currentData?.UserProperties?.KSeF) {
        currentData.UserProperties.KSeF.Authorized = authorization ?? KSeFAuthResult.NotAuthorized;
      }
    });
  }

  public updateAuthorizedInKseFData(ksefData?: KSeFUserPropertiesType): void {
    this.set(currentData => {
      if (currentData?.UserProperties?.KSeF && ksefData) {
        currentData.UserProperties.KSeF = ksefData;
      }
    });
  }

  public updateAutoSendingConfig(autoSendingConfig: AutoSendingInvoicesModel): void {
    this.set(currentData => {
      if (currentData?.UserProperties?.EArchive?.AutoSendingInvoices) {
        currentData.UserProperties.EArchive.AutoSendingInvoices = autoSendingConfig;
      }
    });
  }

  public updateWhiteListConfig(whiteListConfig: NonNullable<CurrentEnvironmentItemFragment['UserProperties']>['EArchive']['WhiteListValidation']): void {
    this.set(currentData => {
      if (currentData?.UserProperties?.EArchive?.WhiteListValidation) {
        currentData.UserProperties.EArchive.WhiteListValidation = whiteListConfig;
      }
    });
  }

  public updateSharedAccessConfig(sharedAccessConfig: NonNullable<CurrentEnvironmentItemFragment['UserProperties']>['EArchive']['SharedAccess']) {
    this.set(currentData => {
      if (currentData?.UserProperties?.EArchive?.SharedAccess) {
        currentData.UserProperties.EArchive.SharedAccess = sharedAccessConfig;
      }
    });
  }

  public updateCredentials(credentials: Array<KSeFTokenCredential> | undefined): void {
    this.set(currentState => {
      if (!currentState?.UserProperties) {
        return;
      }
      currentState.UserProperties.KSeF.Credentials = credentials;
    });
  }

  public configureAutoFetching(data: AutoFetchingInvoicesModel): void {
    this.set(currentState => {
      if (!currentState?.UserProperties) {
        return;
      }
      currentState.UserProperties.EArchive.AutoFetchingInvoices = {...data};
    });
  }

  public configureAutoSending(data: AutoSendingInvoicesModel): void {
    this.set(currentState => {
      if (!currentState?.UserProperties) {
        return;
      }
      currentState.UserProperties.EArchive.AutoSendingInvoices = {...data};
    });
  }

  public override checkIsReady(): boolean {
    return !!this.envId;
  }

  public getCompanyIdFromStore(): string | undefined {
    return localStorageService.getItem(SAVED_COMPANY_ID_KEY) ?? undefined;
  }

  public storeCompanyId(companyId: string) {
    localStorageService.setItem(SAVED_COMPANY_ID_KEY, companyId, 60 * 60 * 24 * 10);
  }

  public setCompanyWithFetch(companyId: string) {
    this.storeCompanyId(companyId);
    this.setEnvId(companyId);
    this.fetch();
  }

  public removeStoredCompanyId() {
    localStorageService.removeItem(SAVED_COMPANY_ID_KEY);
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void | Promise<void> {
    addAlert({
      id: Tr.getCurrentEnvironmentFailed,
      color: ToastVariant.ERROR,
      duration: 10000,
      omitIfHasTheSameAlert: true,
    });
  }

  protected onSuccess(data: CurrentEnvironmentQuery['CurrentEnvironment']): void | Promise<void> {

    if (!data.UserProperties || !this.companyId) {
      return;
    }
    const {Status, LastModifiedBy, LastModifiedDate, ExternalEnabled, InternalEnabled, Minutes, Type, DownloadLimitDate} =
    data.UserProperties?.EArchive.AutoFetchingInvoices ?? {};
    backgroundActionsService.setAutoFetchingInvoices(
      {
        Internal: InternalEnabled,
        External: ExternalEnabled,
        Status,
        LastModifiedBy,
        LastModifiedDate,
        Minutes,
        Type,
        DownloadLimitDate,
      }
    );

    backgroundActionsService.setAutoSendingInvoices(data.UserProperties.EArchive.AutoSendingInvoices);

    this.globalState.notify(this, StoreEvents.CurrentEnvironmentLoaded);
  }

}
