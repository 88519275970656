import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {Key, ReactNode, useMemo} from 'react';
import {Icon, IconColor, IconSize, IconSvg, IconVariant, Tooltip, TooltipPosition} from '@symfonia/brandbook';
import classnames from 'classnames';
import {CircularProgress} from '@mui/material';

export interface MenuItemBtnProps {
  text?: string;
  onClick?: () => void;
  icon?: IconSvg;
  disabled?: boolean;
  tooltipTitle?: string | ReactNode;
  placement?: TooltipPosition;
  testId?: string;
  loading?: (() => boolean) | boolean;
  className?: string;
}

export const MenuItemBtn = observer(({
                                       text,
                                       onClick,
                                       icon,
                                       disabled,
                                       tooltipTitle,
                                       placement,
                                       loading,
                                       className,
                                       testId,
                                     }: MenuItemBtnProps) => {

    const isLoading = typeof loading === 'function' ? loading() : !!loading;

    const iconComponent = useMemo(() => {
      if (isLoading) {
        return <CircularProgress
          color={disabled ? 'secondary' : 'primary'}
          className="!w-[20px] !h-[20px]"
        />;
      }
      if (icon) {
        return <Icon
          svg={icon}
          color={disabled ? IconColor.GREY_800 : IconColor.GREY_500}
          size={IconSize.MD}
          variant={IconVariant.FILLED}
        />;
      }
      return null;
    }, [isLoading, icon, disabled]);

    const button = <button
      data-testid={testId}
      className={classnames('flex px-[12px] py-[16px] items-center w-full hover:filter-primary-500 disabled:filter-grey-200', className)}
      disabled={isLoading || disabled}
      onClick={onClick}>
      {iconComponent}
      <span className={'ml-[10px] font-quicksand font-medium text-base'}>
        {text}
      </span>
    </button>;

    return tooltipTitle ? <Tooltip text={tooltipTitle} position={placement}>
      <div>{button}</div>
    </Tooltip> : button;
  },
);

