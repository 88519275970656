import clsx from 'clsx';
import {FC} from 'react';
import {noop} from 'lodash';
import {
  AlertDirection,
  AlertProps,
  AlertVariant,
  AlertWidth,
  ButtonAlert,
  ButtonAlertVariant,
  Icon,
  IconSize,
  IconSvg,
} from '@symfonia/brandbook';

type Props = Omit<AlertProps, 'description' | 'direction' | 'variant'> & {
  description?: React.ReactNode | string;
  direction?: AlertDirection
  variant?: AlertVariant
};

const getIconSvg = (variant: AlertVariant): IconSvg => {
  switch (variant) {
    case AlertVariant.SUCCESS: {
      return IconSvg.CHECKMARK_CIRCLE;
    }
    case AlertVariant.ERROR:
    case AlertVariant.WARNING: {
      return IconSvg.WARNING_CIRCLE;
    }
    case AlertVariant.INFO:
    default: {
      return IconSvg.INFO;
    }
  }
};

export const FilehubAlert: FC<Props> = ({
  title = '',
  description = '',
  variant = AlertVariant.ERROR,
  width = AlertWidth.FULL,
  onClose = noop,
  actionButton = undefined,
  direction = AlertDirection.VERTICAL,
  className = undefined,
  testId = undefined,
}) => {
  const styles = {
    component: clsx(className, 'p-[16px] rounded-lg flex border border-solid font-quicksand break-all', {
      'w-full': width === AlertWidth.FULL,
      'w-[535px]': width === AlertWidth.BASE && direction === AlertDirection.HORIZONTAL,
      'w-[328px]': width === AlertWidth.BASE && direction === AlertDirection.VERTICAL,
      'w-fit': width === AlertWidth.FIT,
      'bg-primary-100 border-primary-200': variant === AlertVariant.SUCCESS,
      'bg-orange-100 border-orange-200': variant === AlertVariant.WARNING,
      'bg-red-100 border-red-200': variant === AlertVariant.ERROR,
      'bg-blue1-100 border-blue1-200': variant === AlertVariant.INFO,
    }),
    alertIcon: clsx('grow-0 shrink-0', {
      'filter-primary-800': variant === AlertVariant.SUCCESS,
      'filter-orange-800': variant === AlertVariant.WARNING,
      'filter-red-800': variant === AlertVariant.ERROR,
      'filter-blue1-800': variant === AlertVariant.INFO,
    }),
    closeIcon: clsx('grow-0 shrink-0 cursor-pointer', {
      'filter-primary-800': variant === AlertVariant.SUCCESS,
      'filter-orange-800': variant === AlertVariant.WARNING,
      'filter-red-800': variant === AlertVariant.ERROR,
      'filter-blue1-800': variant === AlertVariant.INFO,
    }),
    content: clsx('flex w-full', {
      'px-[8px] flex-col': direction === AlertDirection.VERTICAL,
    }),
    title: clsx('grow font-bold text-base leading-[24px] break-all', {
      'px-[8px]': direction === AlertDirection.HORIZONTAL,
    }),
    description: clsx('grow w-full text-sm leading-[16px]', {
      'px-[8px] pt-[4px]': direction === AlertDirection.HORIZONTAL,
      'pt-[8px]': direction === AlertDirection.VERTICAL,
    }),
    button: clsx('grow', {
      'px-[8px]': direction === AlertDirection.HORIZONTAL,
    }),
  };

  return (
    <div className={styles.component} data-test-element="alert" data-testid={testId}>
      <Icon svg={getIconSvg(variant)} size={IconSize.LG} className={styles.alertIcon}/>
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        {description && <div className={styles.description}>{description}</div>}
        {actionButton && (
          <span className={styles.button} data-testid="button-alert-in-alert">
            <ButtonAlert variant={ButtonAlertVariant[variant]} {...actionButton}/>
          </span>
        )}
      </div>
      {onClose !== noop && (
        <Icon onClick={onClose} svg={IconSvg.CLOSE} size={IconSize.LG} className={styles.closeIcon}/>
      )}
    </div>
  );
};
