import {UserMenuDropdown} from '../UserMenuDropdown/UserMenuDropdown';
import {isEarchive, isLoginHub} from '../../../common/helpers/checkCurrentPath';
import {CompanyDropdownComponent} from './CompanyDropdownComponent';
import {
  Avatar,
  AvatarSize,
  ComponentWithDropdown,
  Icon,
  IconColor,
  IconSize,
  IconState,
  IconSvg,
  IconVariant,
  Separator,
  SeparatorSize,
  TabProps,
} from '@symfonia/brandbook';
import React, {FC} from 'react';
import {useDeviceViewport, EventsBell} from '@symfonia/symfonia-ksef-components';
import {earchiveState, wsEventsRepository} from '@symfonia-ksef/state/rootRepository';
import {observer} from 'mobx-react-lite';
import {AppRoutes} from '../../../root/Routes';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {KSeFConnectionStatus} from '../KSeFConnectionStatus/KSeFConnectionStatus';
import {Tr} from '@symfonia-ksef/locales/keys';
import {redirectToSymfoniaShop} from '../../../common/helpers/redirectToSymfoniaShop';

interface UserMenuProps {
  tabs: ComponentWithDropdown<TabProps>[];
  isAdmin: boolean;
}

export const UserMenuMobile: FC<UserMenuProps> = observer(({tabs, isAdmin}) => {
  const open = earchiveState.layoutState.openDrawer;
  const openNavMenu = earchiveState.layoutState.openNavMenu;
  const setOpenNavMenu = earchiveState.layoutState.setOpenNavMenu;
  const setOpen = earchiveState.layoutState.setOpenDrawer;
  const {FirstName, LastName} = earchiveState.company.currentEnvironment ?? {};
  const {isSmallPhone, isPhone} = useDeviceViewport(earchiveState.layoutState.setViewport);
  const intl = useIntl();
  const navigate = useNavigate();
  const {tenantId, companyId, currentEnvironment} = earchiveState.company;

  const contentClassMobile =
    ' w-full right-0 top-[65px] mt-[2px] pt-0 h-full overflow-y-scroll max-w-[440px] left-0 rounded-lg';
  const contentClassNotMobile = ' h-[408px] w-[461px] shadow-xl rounded-xl top-[66px] right-[50px]';
  const iconsAppearanceClass = ' ml-[10px] min-w-[24px]';

  const iconsAppearance = {
    [IconState.DEFAULT]: {color: IconColor.GREY_800, variant: IconVariant.CONTOUR, size: IconSize.SM},
  };
  const {enabled} = earchiveState.packageStatistics;

  return (
    <>
      <Avatar
        size={AvatarSize.MD}
        className={'ml-[20px]'}
        userName={FirstName}
        userSurname={LastName}
        onClick={() => setOpen(!open)}
      />
      {currentEnvironment && isEarchive() && openNavMenu ? (
        <Icon
          svg={IconSvg.CLOSE}
          appearance={iconsAppearance}
          onClick={() => setOpenNavMenu(false)}
          className={iconsAppearanceClass}
        />
      ) : (
        <Icon
          svg={IconSvg.MENU}
          appearance={iconsAppearance}
          onClick={() => setOpenNavMenu(true)}
          className={iconsAppearanceClass}
        />
      )}

      <div
        className={
          (open ? 'flex' : 'hidden') +
          ' fixed bg-white flex-col z-50 p-[30px] ' +
          (isSmallPhone ? contentClassMobile : contentClassNotMobile)
        }
      >
        <UserMenuDropdown userName={FirstName} userSurname={LastName}/>
      </div>

      {isSmallPhone && <Separator dashed={true} size={SeparatorSize.SM} className={'my-[10px]'}/>}
      <div
        className={
          (openNavMenu ? 'flex' : 'hidden') +
          ' fixed bg-white flex-col z-50 pb-[20px] ' +
          (isSmallPhone ? contentClassMobile : 'h-auto w-[340px] shadow-xl rounded-xl top-[66px] right-[20px]')
        }
      >
        <div className={' p-[20px]'}>
          {tabs.map(t => {
            return (
              <button
                className={'flex w-full mt-[20px] mb-[10px]'}
                onClick={() => {
                  setOpenNavMenu(false);
                  navigate(t.href ?? AppRoutes.eArchive.address);
                }}
                data-testId={`${t.text}MobileMenuButton`}
              >
                <p className={'ml-[10px] text-left'}>{t.text}</p>
              </button>
            );
          })}
        </div>

        {isPhone && (
          <>
            <Separator dashed={true} size={SeparatorSize.SM}/>
            <div className={'px-[20px] p-[10px]'}>
              {currentEnvironment && isEarchive() && !isLoginHub() && (
                <>
                  <KSeFConnectionStatus/>
                  {enabled && (
                    <button
                      className={'flex w-full mt-[20px] mb-[10px]'}
                      onClick={() => {
                        setOpenNavMenu(false);
                        navigate(`/earchive/${tenantId}/company/${companyId}/account/tenant`);
                      }}
                      data-testId={`packageUsageMobileMenuButton`}
                    >
                      <Icon svg={IconSvg.ASSESSMENT} color={IconColor.GREY_500}/>
                      <p className={'ml-[10px] text-left'}>{intl.formatMessage({id: Tr.packageUsageTooltipHeader})}</p>
                    </button>
                  )}
                  {isAdmin && (
                    <button
                      className={'flex w-full mt-[20px] mb-[10px]'}
                      onClick={() => {
                        setOpenNavMenu(false);
                        redirectToSymfoniaShop(earchiveState.packageStatistics);
                      }}
                      data-testId={`packageStatisticsMobileMenuButton`}
                    >
                      <Icon svg={IconSvg.LOCAL_GROCERY_STORE} color={IconColor.GREY_500}/>
                      <p className={'ml-[10px] text-left'}>{intl.formatMessage({id: Tr.buyPackage})}</p>
                    </button>
                  )}
                </>
              )}
              <div className={'flex w-full items-center my-[10px] -ml-[10px]'}>
                <EventsBell
                  redirect={() => navigate(`/earchive/${tenantId}/company/${companyId}/wsevents`)}
                  badge={wsEventsRepository.eventsNumber}
                  isMobile={isPhone}
                  tooltipContent={''}
                />
                <p className={'text-left'}>
                  {intl.formatMessage(
                    {id: Tr.unreadMessageTooltip},
                    {badgeNumber: wsEventsRepository.eventsShortList?.length},
                  )}
                </p>
              </div>

              {earchiveState.company.currentEnvironment && isEarchive() && !isLoginHub() && (
                <CompanyDropdownComponent/>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
});
