import {Tr} from '@symfonia-ksef/locales/keys';
import {FC} from 'react';
import {Dropdown, DropdownWidth} from '@symfonia/brandbook';
import {SymfoniaSystem} from '@symfonia-ksef/graphql';
import {SymfoniaSystemEnum} from '../../../../common/enums/SymfoniaSystemEnum';
import {intl} from '../../../../root/IntlProvider';

const includesFK = (system?: SymfoniaSystem): boolean => {
  if (!system) return false;
  return [
    SymfoniaSystem.Fk,
    SymfoniaSystem.Fksod,
    SymfoniaSystem.FksoDeBiuro,
    SymfoniaSystem.FKeBiuro,
    SymfoniaSystem.Hanfk,
    SymfoniaSystem.Hanfksod,
    SymfoniaSystem.HanfksoDeBiuro,
    SymfoniaSystem.HanfKeBiuro
  ].includes(system);
};

const includesHAN = (system?: SymfoniaSystem): boolean => {
  if (!system) return false;
  return [
    SymfoniaSystem.Han,
    SymfoniaSystem.Hanfk,
    SymfoniaSystem.Hanfksod,
    SymfoniaSystem.HanfksoDeBiuro,
    SymfoniaSystem.HanfKeBiuro,
    SymfoniaSystem.Hansod,
    SymfoniaSystem.HansoDeBiuro,
    SymfoniaSystem.HaNeBiuro
  ].includes(system);
};

export const SystemTypeSwitches: FC<{
  systemType?: number;
  setSystemType: React.Dispatch<React.SetStateAction<number | undefined>>;
  currentSystem?: SymfoniaSystem;
  testId?: string;
}> = ({systemType = 0, setSystemType, currentSystem, testId}) => {
  const hasFK = includesFK(currentSystem);
  const hasHAN = includesHAN(currentSystem);

  const systemTypeOptions = [
    ...(hasFK || hasHAN) ? [{
      label: `${SymfoniaSystem.Han}, ${SymfoniaSystem.Fk}`,
      value: String(SymfoniaSystemEnum.Han ^ SymfoniaSystemEnum.Fk),
    }] : [
      {
        label: SymfoniaSystem.Fk,
        value: String(SymfoniaSystemEnum.Fk),
      },
      {
        label: SymfoniaSystem.Han,
        value: String(SymfoniaSystemEnum.Han),
      },
      {
        label: `${SymfoniaSystem.Han}, ${SymfoniaSystem.Fk}`,
        value: String(SymfoniaSystemEnum.Han ^ SymfoniaSystemEnum.Fk),
      }
    ]
  ];

  return (
    <div className="mt-4">
      <Dropdown
        width={DropdownWidth.FULL}
        value={[String(systemType)]}
        multiple={false}
        placeholder={intl.formatMessage({id: Tr.SaveInvoiceSourceAs})}
        options={systemTypeOptions}
        onChange={val => setSystemType(Number(val[0]))}
        label={intl.formatMessage({id: Tr.SavedTo})}
        testId={testId}
      />
    </div>
);
};
