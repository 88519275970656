export enum InvoicesFilterKeys {
  Numbers = 'Numbers',
  DocumentTypes = 'DocumentTypes',
  IssuerNames = 'IssuerNames',
  IssuerNips = 'IssuerNips',
  RecipientNames = 'RecipientNames',
  RecipientNips = 'RecipientNips',
  NetValue = 'NetValue',
  VatValue = 'VatValue',
  GrossValue = 'GrossValue',
  Currencies = 'Currencies',
  netValueSearchAmount = 'netValueSearchAmount',
  netValueFrom = 'netValueFrom',
  netValueTo = 'netValueTo',
  vatValueSearchAmount = 'vatValueSearchAmount',
  vatValueFrom = 'vatValueFrom',
  vatValueTo = 'vatValueTo',
  grossValueSearchAmount = 'grossValueSearchAmount',
  grossValueFrom = 'grossValueFrom',
  grossValueTo = 'grossValueTo',
  dateFrom = 'DateFrom',
  dateTo = 'DateTo',
  invoiceKsefIssueDate = 'invoiceKsefIssueDate',
  invoiceKsefIssueDateFrom = 'invoiceKsefIssueDateFrom',
  invoiceKsefIssueDateTo = 'invoiceKsefIssueDateTo',
  DateOfIssue = 'DateOfIssue',
  KsefDate = 'KsefDate',
  KsefNumbers = 'KsefNumbers',
  KSeFStatuses = 'KSeFStatuses',
  RegistrationNumber = 'RegistrationNumber',
  InvoicePostingStatuses = 'InvoicePostingStatuses',
}
