import {
  CurrentEnvironmentTenantItemFragment,
  EnvironmentListItemFragment,
  EnvironmentStatus,
  EnvironmentType,
  TenantStatus,
} from '@symfonia-ksef/graphql';
import {StoreMediator} from './EarchiveState';
import {State} from '../../modules/root/services/MobXServices/State';
import {localStorageService} from '../../modules/common/helpers/storage';
import {EnvironmentsState} from '@symfonia-ksef/state/EarchiveState/EnvironmentsState';
import {action} from 'mobx';

const SAVED_TENANT_ID_KEY = 'symfonia.earchive.saved-tenant-id';

export type TenantStateModel = {
  rootTenant: EnvironmentListItemFragment | undefined,
  regularTenant: EnvironmentListItemFragment | undefined,
  firstEnv: EnvironmentListItemFragment | undefined,
  selectedEnvForConfirmation: EnvironmentListItemFragment | undefined,
  selectedTenantForHub: CurrentEnvironmentTenantItemFragment | undefined,
  title: string,
  addTenantEnabled: boolean,
  moduleEnvironmentType: EnvironmentType,
  accountManagementEnabled: boolean,
  contextSwitcherEnabled: boolean,
  shouldGetPermissionsFromCache: boolean
}

export class TenantState extends State<TenantStateModel> {
  constructor(private globalState: StoreMediator) {
    super({
      rootTenant: undefined,
      regularTenant: undefined,
      firstEnv: undefined,
      selectedEnvForConfirmation: undefined,
      selectedTenantForHub: undefined,
      title: '',
      addTenantEnabled: false,
      moduleEnvironmentType: EnvironmentType.None,
      accountManagementEnabled: false,
      contextSwitcherEnabled: false,
      shouldGetPermissionsFromCache: false,
    });

  }

  public removeStoredTenantId() {
    localStorageService.removeItem(SAVED_TENANT_ID_KEY);
  }

  public updateRootTenant(tenant: NonNullable<EnvironmentListItemFragment['Tenant']>): void {
    if (!this.state.rootTenant) {
      return;
    }
    this.override(currentState => {
      if (!currentState.rootTenant) {
        return;
      }
      currentState.rootTenant.Tenant = tenant;
    });
  }

  public findTenantById(tenantId: string | undefined | null, environmentsState: EnvironmentsState) {
    return environmentsState.tenants.find(tenant => tenant.Id === tenantId);
  }

  public updateRootTenantStatus(status: TenantStatus): void {
    if (!this.state.rootTenant) {
      return;
    }

    this.override(currentState => {
      if (!currentState.rootTenant?.Tenant) {
        return;
      }
      currentState.rootTenant.Tenant.Status = status;
    });
  }

  @action.bound
  public setSelectedTenantForHub(tenantId: string | undefined | null, environmentsState: EnvironmentsState) {
    this.set({selectedTenantForHub: this.findTenantById(tenantId, environmentsState)});
  }

  @action.bound
  public setRootTenantOrEnvironment(tenantId: string | undefined | null, environmentsState: EnvironmentsState) {
    const foundTenant = this.findTenantById(tenantId, environmentsState);
    const adminTenant = environmentsState.environments.find(el => el.Type === EnvironmentType.Tenant && el.EnvironmentId === foundTenant?.Id && el.Status === EnvironmentStatus.Active);
    adminTenant ? this.set({rootTenant: adminTenant}) : this.initFirstEnvironment(environmentsState);
  }

  @action.bound
  public setRootTenant(ids: { tenantId?: string | null, companyId?: string | null }, environmentsState: EnvironmentsState) {
    const tenantId = ids.tenantId ?? environmentsState.companies.find(x => x.EnvironmentId === ids.companyId)?.Tenant?.Id;
    const rootTenant = environmentsState.environments.find(x => x.EnvironmentId === tenantId);
    const regularTenant = !rootTenant ? environmentsState.environments.find(x => x.Tenant?.Id === tenantId) : undefined;
    this.set({rootTenant, regularTenant});
  }

  public initRootTenant(environmentsState: EnvironmentsState) {
    const tenantId = this.getTenantIdFromStore();
    const rootTenant = environmentsState.environments.find(env => env.EnvironmentId === tenantId);
    if (rootTenant) {
      this.set({rootTenant});
      return;
    }
    const firstActiveTenantId = environmentsState.tenants.find(tenant => tenant.Status === TenantStatus.Active)?.Id;
    const firstEnvironment = environmentsState.environments.find(env => env.Tenant?.Id === firstActiveTenantId);
    this.set({rootTenant: firstEnvironment});

    if (firstEnvironment) {
      this.storeTenantId(firstEnvironment.EnvironmentId);
    }

    if (!rootTenant) {
      this.initFirstEnvironment(environmentsState);
    }
  }

  public initFirstEnvironment(environmentsState: EnvironmentsState) {
    const firstActiveEnv = environmentsState.environments.filter(el => el.Status === EnvironmentStatus.Active)[0];
    this.set({firstEnv: firstActiveEnv});
  }

  public checkEnvironmentType(type: EnvironmentType): boolean {
    return this.state.moduleEnvironmentType === type;
  }

  public getTenantIdFromStore(): string | undefined {
    return localStorageService.getItem(SAVED_TENANT_ID_KEY) ?? undefined;
  }

  public storeTenantId(tenantId: string) {
    localStorageService.setItem(SAVED_TENANT_ID_KEY, tenantId, 60 * 60 * 24 * 10);
  }
}
