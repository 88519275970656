import {FC} from 'react';
import {useWhiteList} from './useWhiteList';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {Icon, IconColor, IconSize, IconSvg, IconVariant, Toggle, Tooltip} from '@symfonia/brandbook';

export type WhiteListSwitchProps =
  Omit<ReturnType<typeof useWhiteList>, 'handleWhiteListChange'>
  & { onChange: (isActive: boolean) => void, disabled?: boolean, isActive: boolean }

export const WhiteListSwitch: FC<WhiteListSwitchProps> = ({
                                                                  disabled,
                                                                  onChange,
                                                                  isActive,
                                                                  whiteListSettled,
                                                                  whiteListLoading,
                                                                  modifiedBy,
                                                                  modificationDateTime,
                                                                  whiteListIsActive,
                                                                }) => {
  const {formatMessage} = useIntl();

  return <><div className='flex items-center mt-[25px]'>
    <Toggle testId="whiteListSwitch" 
      value=""
      label={<FormattedMessage id={Tr.whiteListSwitchLabel}/>}
      checked={isActive} 
      disabled={disabled || whiteListLoading}
      onChange={(status) => onChange(status)}/>
    <Tooltip position='right' text={formatMessage({id: Tr.whiteListInfo})}>
      <Icon svg={IconSvg.WARNING_CIRCLE} size={IconSize.LG} variant={IconVariant.CONTOUR} color={IconColor.BLUE1_500} className='ml-[10px]'/>
    </Tooltip>
  </div>
  {whiteListSettled && <div className='flex items-center mt-[15px]'>
    <Icon svg={IconSvg.WARNING_CIRCLE} size={IconSize.MD} variant={IconVariant.CONTOUR} color={IconColor.GREY_500} className='mr-[10px]'/>
    <span test-id="whiteListActivityInfoParagraph" className="text-grey-500">
      {formatMessage({id: Tr.whiteListActivationInfo}, {
         activation: formatMessage({id: whiteListIsActive ? Tr.whoActivated : Tr.whoDeactivated}),
           user: modifiedBy,
           dateTime: modificationDateTime,
      })}
    </span>
  </div>}  
  </>;  
};
