import {BrowserCacheLocation, ILoggerCallback, PublicClientApplication} from '@azure/msal-browser';

import {environment} from '../../../environments/environment';
import {authorityFactory} from './AuthorityFactory';
import {AppRoutes} from '../../root/Routes';
import {isDesktop} from '../../common/helpers/checkCurrentPlatform';

export function authClientFactory(loggerCallback: ILoggerCallback): PublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalClientId,
      authority: authorityFactory(environment.msalSignInPolicy),
      knownAuthorities: [environment.msalDomain],
      redirectUri: environment.msalRedirectUri,
      postLogoutRedirectUri: !isDesktop ? AppRoutes.eArchive.address : environment.msalPostLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false
    },
    system: {
      loggerOptions: {
        loggerCallback
      }
    }
  });
}
