import {useIntl} from 'react-intl';
import {Outlet} from 'react-router-dom';
import {useIsRouteActive} from '../../../common';
import {IAccountState} from './IAccountState';
import {observer} from 'mobx-react-lite';
import {FC, useEffect} from 'react';
import {usePostingConfigurationStatus} from '../../hooks/usePostingConfigurationStatus';
import {earchiveStateMobx} from '../../EArchiveModule';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {IModuleStatus} from '@symfonia-ksef/state/ModuleSettings/IModuleStatus';
import {configMenuSections} from '../../../layout/components/ConfigMenu/configMenuSections';
import {ConfigMenu} from '../../../layout/components/ConfigMenu/ConfigMenu';

export interface AccountPageProps {
  state: IAccountState;
  modules: IModuleStatus[];
}

export const AccountPage: FC<AccountPageProps> = observer(({state, modules}) => {
  const intl = useIntl();
  const {getRequiredConfigurationStatusForFinancialYearId} = usePostingConfigurationStatus();

  const availableSections = configMenuSections(intl, useIsRouteActive, modules);
  const availableSectionsFiltered = availableSections.filter(
    s => !s.featureProps || (s.featureProps && s.featureProps.moduleStatus !== undefined),
  );

  useEffect(() => {
    getRequiredConfigurationStatusForFinancialYearId();
  }, []);

  // Update UserProperties regarding AutoPosting
  useEffect(() => {
    if (earchiveStateMobx.postingState.isPostingConfigured && !earchiveState.company.autoPosting?.Status) {
      earchiveState.company.fetch();
    }
  }, []);

  return (
    <div className={'sticky flex flex-row overflow-hidden lg:h-[calc(100vh - 65px)] flex-auto max-w-full'}>
      <div className={'flex-col max-w-[250px] h-full flex-auto z-50 sm:flex hidden'}>
        <ConfigMenu
          sections={availableSectionsFiltered}
          selectedCompanyEnvironment={earchiveState.company.currentEnvironment ?? undefined}
        />
      </div>
      <div className={'grow flex flex-col managmentPageResponsive'}>
        <Outlet/>
      </div>
    </div>
  );
});
