import {FC} from 'react';
import {ButtonTertiary, ButtonTertiarySize, IconSvg} from '@symfonia/brandbook';
import {useNavigate} from 'react-router-dom';
import {AppRoutes} from '../../../Routes';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const SelectCompanyButton: FC<{ companyId: string, text: string, tenantId: string, testId?: string }> = ({
                                                                                                 companyId,
                                                                                                 text,
                                                                                                 tenantId,
                                                                                                 testId
                                                                                               }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    earchiveState.tenant.storeTenantId(tenantId);
    earchiveState.company.setCompanyWithFetch(companyId);
    navigate(AppRoutes.eArchive.address + '/' + tenantId + '/company/' + companyId + '/documents/purchase', {state: {reloadStorageFilters: true}});
  };
  return <ButtonTertiary
    lIcon={IconSvg.OPEN_IN_BROWSER}
    size={ButtonTertiarySize.SM}
    text={text}
    onClick={handleClick}
    testId={testId}
  />;
};
