import {FormattedMessage} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import React, {FC} from 'react';
import {DropzoneProps, InputProps} from '../FilesUploader/DropzoneUploaderModule/DropzoneContainer';
import {observer} from 'mobx-react-lite';
import {Text} from '../../../root/components/Text';
import {ButtonTertiary, ButtonTertiarySize, Icon, IconSize, IconSvg} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';

export type UploadXMLFile = { inputProps: InputProps, disabled: boolean, dropzoneProps: DropzoneProps, loading: boolean }

export const UploadXMLFile: FC<UploadXMLFile> = observer(({inputProps, dropzoneProps, disabled, loading}) => {
  return <>
    <Text block className="!text-xl mb-[8px] text-slate-900 font-medium uppercase text-center">
      <FormattedMessage id={Tr.uploadFilesWindowHeader}/>
    </Text>
    <Text block small className="mb-[8px] text-slate-700 text-center">
      <FormattedMessage id={Tr.uploadFilesWindowContent}/>
    </Text>
    <div
      className="activeDropZoneContainer"
      style={disabled ? {cursor: 'no-drop'} : undefined}
      {...dropzoneProps}
    >
      <Icon size={IconSize.LG} className="!w-[100px] !h-[100px] mb-[24px]" svg={IconSvg.POST_ADD}/>
      <Text block className="text-slate-900 font-bold">
        <FormattedMessage id={!inputProps.multiple ? Tr.uploadOneBoxContent : Tr.uploadManyBoxContent}/>
      </Text>
      <div className="flex items-center">
        <Text small className="text-slate-900 mr-[6px]"><FormattedMessage id={Tr.or}/></Text>
        <ButtonTertiary
          size={ButtonTertiarySize.SM}
          loading={loading}
          text={intl.formatMessage({id: Tr.chooseFileFromDisk}, {i: !inputProps.multiple ? '' : 'i'})}
          onClick={inputProps.triggerUploadWindow}
          testId='uploadXmlFileButton'
        />
      </div>
    </div>
  </>;
});
