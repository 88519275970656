import {observer} from 'mobx-react-lite';
import {Fragment, ReactNode, useMemo, useState} from 'react';
import {ButtonTertiary, ButtonTertiarySize, IconSvg, IconTag, IconTagSize} from '@symfonia/brandbook';
import {useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {isEmpty} from 'lodash';

export type Pill = {
  label: string;
  key: string;
  onClick: () => void;
};

export type FilterPills = {filterLabel: string; items: Pill[]};

export type Pills<T extends string = string> = Record<T, FilterPills>;

export type FiltersPillsProps<T extends string = string> = {
  pills?: Pills<T>;
  maxVisiblePills?: number;
  className?: string;
};

type FlattenPill<T extends string = string> = {
  onClick(): void;
  filterLabel: string;
  filterKey: T;
  key: string;
  label: string;
};

export const FiltersPills = observer(<T extends string>({pills, maxVisiblePills, className}: FiltersPillsProps<T>) => {
  const [expanded, setExpanded] = useState(false);

  const intl = useIntl();

  const pillsList = useMemo<FlattenPill<T>[]>(() => {
    if (!pills) {
      return [];
    }
    return Object.entries<FilterPills>(pills).reduce<FlattenPill<T>[]>((acc, [filterKey, pill]) => {
      const {filterLabel, items} = pill;
      acc.push(
        ...items.map(item => ({
          filterLabel,
          filterKey: filterKey as T,
          ...item,
        })),
      );
      return acc;
    }, []);
  }, [pills]);

  const expandedPills = useMemo<FlattenPill<T>[]>(
    () => (expanded ? pillsList : pillsList.slice(0, maxVisiblePills)),
    [pillsList, maxVisiblePills, expanded],
  );

  const filtersPillsComponents = useMemo<JSX.Element[]>(() => {
    type TempFilterPills = Record<T, {filterLabel: string; items: ReactNode[]}>;
    const pills = expandedPills.reduce<TempFilterPills>((acc, {filterKey, filterLabel, ...item}, idx) => {
      if (!(filterKey in acc)) {
        acc[filterKey] = {filterLabel, items: []};
      }
      acc[filterKey].items.push(
        <span className="mr-[8px] mb-[8px]" key={`${item.key}-${idx}`}>
          <IconTag
            size={IconTagSize.SM}
            actionIcon={IconSvg.CLOSE}
            onActionIconClick={item.onClick}
            text={item.label}
          />
        </span>,
      );
      return acc;
    }, {} as TempFilterPills);

    return Object.entries<{filterLabel: string; items: ReactNode[]}>(pills).map(
      ([filterKey, {filterLabel, items}], idx) => {
        return (
          <Fragment key={`${filterKey}-${idx}`}>
            <span className="mr-[4px] mb-[8px]">
              {filterLabel}:
            </span>
            {items}
          </Fragment>
        );
      },
    );
  }, [expandedPills]);

  const rest = Math.max(pillsList.length - (maxVisiblePills ?? pillsList.length), 0);

  if (!pills || isEmpty(pills)) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex flex-wrap">
        {filtersPillsComponents}
        {!!rest && (
          <ButtonTertiary
            size={ButtonTertiarySize.SM}
            onClick={() => setExpanded(expanded => !expanded)}
            text={intl.formatMessage({id: expanded ? Tr.less : Tr.more}, {prefix: rest ? `+${rest} ` : ''})}
            rIcon={expanded ? IconSvg.ARROW_DROP_UP : IconSvg.ARROW_DROP_DOWN}
            testId='expandPillsButton'
          />
        )}
      </div>
    </div>
  );
});
