import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {intl} from '../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonTertiary, ButtonTertiarySize, IconSvg, Input, InputSize, InputWidth} from '@symfonia/brandbook';
import {getSize, intlFunction} from '../utils/utils';
import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {debounce} from '../utils/utils';
import {dateParser} from '../../common';

type Props = {
  clearSelection: () => void;
  openAddAttachmentModal: () => void;
  state: IFilehubState;
};

const FilehubModalHeader = observer(({clearSelection, openAddAttachmentModal, state}: Props) => {
  const isPurchaseInvoice = location.pathname.includes('/documents/purchase');

  const handleDebouncedSearch = debounce((value: string) => {
    state.setDebouncedSearch(value.toLocaleLowerCase());
  }, 500);

  const handleSearch = (value: string) => {
    clearSelection();
    state.setSearch(value);
  };

  useEffect(() => {
    handleDebouncedSearch(state.search);
  }, [state.search, handleDebouncedSearch]);

  useEffect(() => {
    const attachmentsWithoutFilters = state.attachmentsWithoutFilters;

    const filteredAttachments = attachmentsWithoutFilters.filter(attachment => {
      if (state.debouncedSearch === '') {
        return attachment;
      }
      const status: string = attachment.IsPublic
        ? intl.formatMessage({id: Tr.publicStatus})
        : intl.formatMessage({id: Tr.privateStatus});
      const size: string = attachment.Size ? getSize(attachment.Size) : '0 B';
      const createdAt = attachment.CreatedAt ? dateParser(attachment.CreatedAt) : '';

      if (
        createdAt.includes(state.debouncedSearch) ||
        attachment.CreatedByEmail?.toLocaleLowerCase().includes(state.debouncedSearch) ||
        size.toLocaleLowerCase().includes(state.debouncedSearch) ||
        attachment.FileName?.toLocaleLowerCase().includes(state.debouncedSearch) ||
        status.toLocaleLowerCase().includes(state.debouncedSearch)
      ) {
        return attachment;
      } else {
        return;
      }
    });

    state.setAttachments(filteredAttachments);
  }, [state.debouncedSearch]);

  return (
    <div className="flex flex-col gap-[24px]">
      <div>
        {intl.formatMessage({id: isPurchaseInvoice ? Tr.invoicePURCHASE : Tr.invoiceSALES})},{' '}
        <strong>{state.invoiceNumber}</strong>
      </div>
      <div className="flex justify-between items-center">
        <div>
          <Input
            onInput={handleSearch}
            placeholder={intlFunction(Tr.search)}
            rightIcon={IconSvg.SEARCH}
            value={state.search}
            width={InputWidth.FULL}
            size={InputSize.SM}
          />
        </div>
        <div>
          <ButtonTertiary
            lIcon={IconSvg.NOTE_ADD}
            onClick={openAddAttachmentModal}
            size={ButtonTertiarySize.SM}
            text={intl.formatMessage({id: Tr.addAttachments})}
          />
        </div>
      </div>
    </div>
  );
});

export default FilehubModalHeader;
