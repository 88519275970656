import {action, makeObservable, observable, toJS} from 'mobx';
import {BaseFilterState} from './BaseFilterState';
import {IBaseSideMenuFilterState} from './IBaseSideMenuFilterState';
import {isEmptyObj} from '../../modules/common/helpers/helperFunctions';

export abstract class BaseSideMenuFilterState<T, K extends string>
  extends BaseFilterState<T, K>
  implements IBaseSideMenuFilterState<T, K> {

  @observable
  isFilterMenuOpen = false;

  constructor(parent: T) {
    super(parent);
    makeObservable(this);
  }

  @action.bound
  public setOpenFilterMenu(val: boolean) {
    this.isFilterMenuOpen = val;
  }

  public override handleClearMenuFilter() {
    this.activeFilters.clear();
    this.pillsList.clear();
    this.resetStorageFilters(this.pageFilters);
    this.resetFilterToStorage();
    this.setDefaultValues();
  }

  public override startFiltering() {
    this.activeFilters.forEach(f => {
      if (f.values.length > 0 || f.values.every(x => typeof x === 'object' && isEmptyObj(x))) f.isActive = true;
      if (f.isRangeFilter && f.values.every(val => Object.values(val).every(value => value === undefined))) {
        f.isActive = false;
      } 
      if (f.pills !== undefined) this.createPills();
    });
  }
}
