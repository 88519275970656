import {CompanyKsefServicesStatusEnum} from '@symfonia-ksef/graphql';
import {useCallback, useEffect, useState} from 'react';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {useModule} from '../../../../../root/services/MobXServices/BaseModule';
import {InvoicesSharedAccess} from './InvoicesSharedAccess';

export function useSharedAccess(opt?: { loadOnMount: boolean }) {
  const sharedAccessModule = useModule(() => new InvoicesSharedAccess(earchiveState.company, earchiveState, !!opt?.loadOnMount));
  const {disabled, settled, modifiedBy, modificationDateTime, authorizedInKSeF} = sharedAccessModule;
  const [isActive, setIsActive] = useState<boolean>(() => sharedAccessModule.isActive);

  const onChange = useCallback((isActive: boolean) => setIsActive(isActive), []);

  useEffect(() => {
    if (!authorizedInKSeF && isActive) setIsActive(sharedAccessModule.isActive);
  }, [authorizedInKSeF, isActive, sharedAccessModule]);

  const isDirty: boolean = sharedAccessModule.isActive !== isActive;

  const handleSharedAccessChange = useCallback(async () => {
    if (!isDirty) {
      return;
    }
    await sharedAccessModule.configure({Status: isActive ? CompanyKsefServicesStatusEnum.Active : CompanyKsefServicesStatusEnum.Inactive}).fetch();
  }, [isDirty, isActive, sharedAccessModule]);

  return {
    isActive,
    onChange,
    sharedAccessLoading: sharedAccessModule.loading,
    handleSharedAccessChange,
    sharedAccessIsActive: sharedAccessModule.isActive,
    sharedAccessDisabled: disabled || !authorizedInKSeF,
    sharedAccessSettled: settled,
    modifiedBy,
    modificationDateTime,
    isDirty,
  };
}
