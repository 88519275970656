import {AnyObject} from 'yup/es/types';
import {Cell, Row} from '../services/TableServices/BaseTableService';
import {memo, useEffect, useMemo, useRef, useState} from 'react';
import {Tooltip} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import classnames from 'classnames';
import {ExtendedTableServiceI} from '../services/TableServices/ExtendedTableService';


export type TableCellProps<T extends AnyObject, C extends AnyObject = AnyObject> =
  Cell<T, C> & {
  row: Row<T>,
  tableService?: Partial<Pick<ExtendedTableServiceI<T>, 'focusedRowClassName' | 'selectedRowClassName' | 'checkIsFocused' | 'checkIsSelected'>>
}

export const TableCell = <T extends AnyObject, P extends AnyObject = AnyObject, C extends AnyObject = AnyObject>({
                                                                                                            content,
                                                                                                            tooltipContent,
                                                                                                            onClick,
                                                                                                            tableService,
                                                                                                            row,
                                                                                                            ellipsisEnabled,
                                                                                                            alignRight,
                                                                                                          }: TableCellProps<T, C>) => {


  const selectedRowClassName = tableService?.selectedRowClassName ?? '';
  const focusedRowClassName = tableService?.focusedRowClassName?.(row) ?? '';
  const isSelected = !!tableService?.checkIsSelected?.(row);
  const isFocused = !!tableService?.checkIsFocused?.(row);
  const textElementRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [isOverflowed, setIsOverflow] = useState(false);

  const classes = useMemo(() => ({
    [selectedRowClassName]: isSelected && !isFocused,
    [focusedRowClassName]: isFocused,
    'text-right pr-[16px]': alignRight,
    'overflow-hidden whitespace-nowrap overflow-ellipsis': ellipsisEnabled,
  }), [isSelected, isFocused, tableService?.selectedRowClassName, focusedRowClassName, alignRight, ellipsisEnabled]);


  const cellContent = useMemo(() => <span onClick={onClick} ref={textElementRef} 
                                          className={classnames('overflow-hidden pt-[12px] pl-[16px] w-full h-full relative', classes)}
  >
    {content}
  </span>, [content, onClick, classes]);

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);

  return tooltipContent && isOverflowed ? <Tooltip text={tooltipContent}>{cellContent}</Tooltip> : cellContent;
 };


export const ObserverTableCell = memo(observer(TableCell));
