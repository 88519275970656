import {
  SubscribeGetUpoQueryVariables,
  UpoFileType,
  UpoMissingInvoiceReason,
  WebsocketErrorType,
  WebSocketNotificationFragment,
  WebsocketNotificationType,
} from '@symfonia-ksef/graphql';
import {action, computed, makeObservable, observable} from 'mobx';
import {MissingInvoiceModel} from '../../modules/earchive/models';
import {base64ToBlob} from '../../modules/common';
import fileSaver from 'file-saver';
import {GraphQLErrorWithMessage} from '../../modules/root/providers/GraphQLProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {EventJobRunnerI} from '../../services/EventJobRunner';
import {GenerateUpoJobRunner, GenerateUpoJobRunnerI} from '../../services/KSeFJobRunners/GenerateUpoJobRunner';
import {addAlert} from '../../services/helpers/AlertService';
import {Notification, NotificationDataService} from '../../services/NotificationDataService';
import {
  NotificationDataResultManager,
  NotificationDataResultManagerI,
} from '../../services/NotificationDataResultManager';
import {UPODataModel, UPOMimeType} from '../../services/helpers/NotificationDataParsers';
import {EnvObserverI} from '@symfonia-ksef/state/EarchiveState/services/EnvObserver';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {upoMissingInvoicesReasonMap} from '../../modules/earchive/modules/KSeFEvents/services/KSeFEventsConverters/UpoEventConverter';
import {UpoModelMapper} from '../../modules/earchive/modules/KSeFEvents/helpers/UpoModelMapper';
import {getUnique} from '../../modules/earchive/helpers/getUnique';

export interface IGetUPOResultService {
  jobRunner: EventJobRunnerI<SubscribeGetUpoQueryVariables>;
  errorItems: MissingInvoiceModel[];
  resultIsAvailable: boolean;
  modalIsActive: boolean;
  errorType: WebsocketErrorType | undefined;

  setModalIsActive(isActive: boolean): this;

  setResultIsAvailable(isAvailable: boolean): this;

  setErrorType(errorType: WebsocketErrorType | undefined): this;
}

export type UPOModelToMap =
  Omit<UPODataModel, 'missingInvoices' | 'fileType'>
  & { fileType: number, missingInvoices?: { invoiceId: string, reason: number, invoiceNumber: string, }[] }

export class GetUPOResultService extends NotificationDataService<WebsocketNotificationType.GetUpo, SubscribeGetUpoQueryVariables, GenerateUpoJobRunnerI, UPOModelToMap> implements IGetUPOResultService {

  @observable.ref
  public errorItems: MissingInvoiceModel[] = [];

  @observable
  public modalIsActive: boolean = false;

  protected dataResultManager: NotificationDataResultManagerI<UPODataModel>;

  constructor(envObserver: EnvObserverI, earchiveState: EArchiveState) {
    super(envObserver, earchiveState, () => new GenerateUpoJobRunner(envObserver, earchiveState), UpoModelMapper.map);
    makeObservable(this);
    this.dataResultManager = new NotificationDataResultManager<UPODataModel>(this);
  }

  @computed
  public get currentResult(): UPODataModel | null {
    return this.dataResultManager.currentResult;
  }

  @computed
  public get hasReturnedInvoices(): boolean {
    return !!this.currentResult?.returnedInvoices && this.uniqueMissingInvoicesCount < (this.currentResult?.requestedInvoices?.length ?? 0);
  }

  @computed
  public get hasDownloadableContent(): boolean {
    return !!(this.currentResult?.mimeType && this.currentResult?.fileContent);
  }

  @computed
  public get missingItems(): MissingInvoiceModel[] {
    return this.currentResult?.missingInvoices?.filter?.(Boolean)?.map?.(missingInvoice => ({
      reason: missingInvoice.reason ? upoMissingInvoicesReasonMap.get(missingInvoice.reason) : UpoMissingInvoiceReason.Error,
      invoiceNumber: missingInvoice?.invoiceNumber ?? '',
    })) ?? [];
  }

  @computed
  public get uniqueMissingInvoicesCount(): number {
    return this.currentResult?.missingInvoices ? getUnique(this.currentResult?.missingInvoices, invoice => invoice.invoiceId).length : 0;
  }

  @computed
  public get requestedInvoicesCount() {
    return this.currentResult?.requestedInvoices?.length ?? 0;
  }

  @action.bound
  public setModalIsActive(isActive: boolean): this {
    this.modalIsActive = isActive;
    return this;
  }


  public downloadUpoFile(): { hasMissed: boolean, success: boolean, hasReturned: boolean } {
    const hasMissed = !!this.currentResult?.missingInvoices?.length;
    const hasReturned = this.hasReturnedInvoices;
    try {
      if (!hasReturned) {
        throw Error('There are no UPOs returned');
      }
      this.tryDownloadUpoFile();
      return {hasMissed, success: true, hasReturned};
    } catch (err) {
      hasMissed || hasReturned || setTimeout(() => addAlert({
        id: Tr.getUpoFileError,
        duration: 8000,
        moveToTopIfAlreadyExist: true,
      }), 1000);
      return {hasMissed, success: false, hasReturned};
    }
  }

  public checkIsPending(identifier: string | undefined, fileType: UpoFileType): boolean {
    if (!identifier) {
      return false;
    }
    return this.activeEvents.some(event => event.data?.fileType === fileType && event.data?.requestedInvoices?.includes?.(identifier));
  }

  public handleMissingInvoices(): this {
    if (!this.currentResult) {
      return this;
    }
    return this;
  }

  @action.bound
  public setArchivedResult(result: UPODataModel, NotificationId: string): this {
    this.dataResultManager.setArchivedResult(result, NotificationId);
    return this;
  }

  public clearFetchedResult(): void {
    this.setFetchingResult(null, null);
  }

  protected override onSuccess(data: WebSocketNotificationFragment): void {
    super.onSuccess(data);
    if (!this.result) {
      return;
    }

    this.setFetchingResult({...this.result}, this.notification);
    this.handleMissingInvoices();
  }

  protected override onResultSettled(result: UPODataModel | null, manualSettled: boolean) {
    if (result && this.notification && manualSettled) {
      this.setArchivedResult(result, this.notification.NotificationId);
    }
  }

  protected override onError(errors: readonly GraphQLErrorWithMessage[], error: string | null): void {
    this.clearFetchedResult();
  }


  private setFetchingResult(result: UPODataModel | null, notification: Notification): this {
    this.dataResultManager.setFetchingResult(result, notification);
    return this;
  }

  @action
  private setErrorsItems(errors: MissingInvoiceModel[]): void {
    this.errorItems = errors;
  }


  private tryDownloadUpoFile(): void {
    if (!this.hasDownloadableContent) {
      throw Error('Download invoices fail');
    }

    const {fileContent, fileName, mimeType} = this.currentResult as UPODataModel;
    const blob = base64ToBlob(fileContent as string, mimeType as UPOMimeType);
    const name = fileName || `UPO-${new Date().getTime()}`;
    fileSaver.saveAs(blob, name);
  }
}
