export const colors = {
  primary: {
    900: '#002E00',
    800: '#003E00',
    700: '#004D00',
    600: '#006C00',
    500: '#009A00',
    400: '#66C266',
    300: '#8CD28C',
    200: '#B3E1B3',
    100: '#D9F0D9',
    50: '#F4FBF4',
  },
  red: {
    900: '#2C0C10',
    800: '#411319',
    700: '#6D1F29',
    600: '#992B39',
    500: '#DA3E52',
    400: '#E05B6C',
    300: '#E98B97',
    200: '#F2BBC2',
    100: '#F8D8DC',
    50: '#FDF2F3',
  },
  orange: {
    900: '#412913',
    800: '#623E1C',
    700: '#8E5928',
    600: '#B97435',
    500: '#DA893E',
    400: '#E1A065',
    300: '#E7B282',
    200: '#F0D0B2',
    100: '#F8E7D8',
    50: '#FBF2E9',
  },
  yellow: {
    900: '#463816',
    800: '#685521',
    700: '#977A30',
    600: '#C5A03F',
    500: '#E8BC4A',
    400: '#EDC96E',
    300: '#F1D792',
    200: '#F6E4B7',
    100: '#F9EED2',
    50: '#FDF8ED',
  },
  green: {
    900: '#193B09',
    800: '#26590E',
    700: '#378014',
    600: '#47A71A',
    500: '#58BE27',
    400: '#7FD356',
    300: '#98DC78',
    200: '#BBE8A5',
    100: '#E4F6DB',
    50: '#F3FBEF',
  },
  blue1: {
    900: '#0D1B27',
    800: '#13293B',
    700: '#204462',
    600: '#2C5F8A',
    500: '#3F88C5',
    400: '#5C9ACE',
    300: '#8CB8DC',
    200: '#BCD5EB',
    100: '#D9E7F3',
    50: '#EFF5FA',
  },
  purple: {
    900: '#1C1A23',
    800: '#2A2634',
    700: '#464056',
    600: '#635A79',
    500: '#8D80AD',
    400: '#9E93B9',
    300: '#BBB3CE',
    200: '#D7D3E2',
    100: '#E8E6EF',
    50: '#F4F3F7',
  },
  blue2: {
    900: '#0C0A14',
    800: '#151223',
    700: '#1C192F',
    600: '#25213E',
    500: '#2E294E',
    400: '#4D4969',
    300: '#77748C',
    200: '#ABA9B8',
    100: '#D5D4DC',
    50: '#F1F1F4',
  },
  grey: {
    900: '#000000',
    800: '#202020',
    700: '#3A3A3A',
    600: '#4D4D4D',
    500: '#808080',
    400: '#939393',
    300: '#B3B3B3',
    200: '#D3D3D3',
    100: '#E6E6E6',
    50: '#F2F2F2',
  },
};
