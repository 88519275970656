export const environmentCommon = {
  defaultLocale: 'pl',
  apiUrl: `${process.env.NX_GRAPHQL_API_URL}`,
  appInsightsInstrumentationKey: `${process.env.NX_APPINSIGHTS_INSTRUMENTATION_KEY}`,
  reCaptchaKey: `${process.env.NX_RE_CAPTCHA_KEY}`,
  msalClientId: `${process.env.NX_AUTHENTICATION_CLIENT_ID}`,
  msalTenantId: `${process.env.NX_AUTHENTICATION_TENANT_ID}`,
  msalDomain: `${process.env.NX_AUTHENTICATION_DOMAIN}`,
  msalSignInPolicy: `${process.env.NX_AUTHENTICATION_SIGN_IN_POLICY}`,
  msalResetPasswordPolicy: `${process.env.NX_AUTHENTICATION_PASSWORD_RESET_POLICY}`,
  msalEditProfilePolicy: 'B2C_1_edit_profile',
  msalScopes: process.env.NX_AUTHENTICATION_SCOPES?.split(',') || [],
  msalRedirectUri: `${window.location.origin}`,
  msalPostLogoutRedirectUri: `${window.location.pathname}`,
  privacyPolicyDocumentUrl: 'https://symfonia.pl/prywatnosc-i-cookies/',
  cloudConsentDocumentUrl: 'https://materialy.symfonia.pl/publiczne/8d0b44?_gl=1*1mwyxw6*_ga*NTYzODY5NDI4LjE2ODA3NzQzNTM.*_ga_G3P4SPM583*MTcwMzc2MjU3Ny43OS4wLjE3MDM3NjI1NzcuNjAuMC4w',
  helpDocumentUrl: 'https://pomoc.symfonia.pl/',
  onlineDocumentationUrl: 'https://pomoc.symfonia.pl/Help/ksef/-/-/262152',
  contactDocumentUrl: 'https://wsparcie.symfonia.pl/hc/pl/articles/360019565920-Mam-wykupion%C4%85-us%C5%82ug%C4%99-jak-uzyska%C4%87-wsparcie',
  personalDataForPartnersDocumentUrl: 'https://symfonia.pl/partnerzy',
  hotjarHjid: Number(process.env.NX_HOTJAR_HJID),
  hotjarHjsv: Number(process.env.NX_HOTJAR_HJSV),
};

export enum Environments {
  local = 'local',
  dev = 'dev',
  test = 'test',
  sokudoTest = 'sokudoTest',
  sokudo = 'sokudo',
  biedronki = 'biedronki',
  preProd = 'preProd',
  demo = 'demo',
  prod = 'prod',
  unknown = 'unknown'
}

const VALID_HOSTNAMES: Readonly<Omit<Record<Environments, string>, 'unknown'>> = Object.freeze({
  local: 'localhost',
  dev: 'dev.hub01.symfoniadev.pl',
  test: 'test.hub01.symfoniadev.pl',
  sokudoTest: 'sokudoTest.hub01.symfoniadev.pl',
  sokudo: 'sokudo.hub01.symfoniadev.pl',
  biedronki: 'biedronki.hub01.symfoniadev.pl',
  preProd: 'app-pprod.symfonia.pl',
  demo: 'app.demo.symfonia.pl',
  prod: 'app.symfonia.pl',
});

const {local, dev, test, sokudoTest, sokudo, biedronki, preProd, demo, prod, unknown} = Environments;

const DEV_ENVS: readonly Environments[] = Object.freeze([local, dev, test, sokudoTest, sokudo, biedronki]);
const STAGING_ENVS: readonly Environments[] = Object.freeze([preProd, demo]);
const PROD_ENVS: readonly Environments[] = Object.freeze([prod, unknown]);

const ocrUrlsMap: Map<readonly Environments[], string> = new Map()
  .set(DEV_ENVS, 'https://sm-dev.symfoniadev.pl')
  .set(STAGING_ENVS, 'https://sm-next.symfoniadev.pl')
  .set(PROD_ENVS, 'https://apps.symfonia.pl');


export const CURRENT_ENV: Environments = Object.entries(VALID_HOSTNAMES).find(([_, hostname]) => hostname === document.location.hostname)?.[0] as Environments ?? unknown;

export const OCR_URL = ocrUrlsMap.get([DEV_ENVS, STAGING_ENVS, PROD_ENVS].find(envs => envs.some(env => env === CURRENT_ENV)) ?? PROD_ENVS);

export const SHOP_BASE_URL = CURRENT_ENV === Environments.prod ? 'https://sklep.symfonia.pl' : ' https://symfonia--uat.sandbox.my.site.com';
