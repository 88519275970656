import {FormattedMessage, useIntl} from 'react-intl';
import {CopyToClipboardButton} from '../Forms';
import {Tr} from '../../../../locales/translationKeys';
import {IconColor, IconSize, IconSvg, Input, InputSize, InputWidth, Tooltip, TooltipPosition} from '@symfonia/brandbook';
import {IconWithTooltip} from '@symfonia/symfonia-ksef-components';
import {Text} from '../../../root/components/Text';

export const CompanyKeyField = ({companyKey}: {companyKey: string}) => {
  const intl = useIntl();
  const inputSize = InputSize.SM;
  const inputWidth = InputWidth.FULL;

  const {companyKey: companyKeyTranslation, companyKeyTooltip_Content, companyKeyTooltip_Header} = Tr;

  return (
    <div className="flex items-center w-full">
      <div className="flex-auto max-w-[280px]">
          <Input
            disabled
            label={
              <div className="flex items-center mb-[8px]">
                <Text className="text-sm text-grey-400">
                  <FormattedMessage id={companyKeyTranslation}/>
                </Text>
                <IconWithTooltip
                  tooltipWidth="auto"
                  tooltipClass={'w-[800px]'}
                  icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
                  size={IconSize.MD}
                  btnClass={'flex items-center ml-[4px]'}
                  tooltipPosition={TooltipPosition.RIGHT}
                  tooltipContent={<>
                    <h1 className={'mb-[10px] font-bold'}>{intl.formatMessage({id: companyKeyTooltip_Header})}</h1>
                    <p>{intl.formatMessage({id: companyKeyTooltip_Content}, {br: <br/>})}</p>
                  </>
                  }
                />
              </div>
            }
            size={inputSize}
            value={companyKey}
            width={inputWidth}
            testId="companyInfoCompanyKey"
            inputClassName="pointer-events-none"
            rightIcon={<CopyToClipboardButton onClick={() => companyKey} id={companyKey} className="mx-[4px]"/>}
          />
      </div>
    </div>
  );
};