import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {InvoiceDownloadModalProps} from '../components/InvoicesTable/Components/InvoiceDownloadedModal';
import {MutationSetInvoiceDownloadedArgs, useSetInvoiceDownloadedMutation} from '@symfonia-ksef/graphql';
import {ToastVariant} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage} from 'react-intl';
import {useCallback} from 'react';
import {SymfoniaSystemEnum} from '../../common/enums/SymfoniaSystemEnum';
import {intl} from '../../root/IntlProvider';

export interface SetInvoiceDownloadedProps {
  modalIsActive: boolean;
  invoiceId?: string;
}

const successText = (systemType: SymfoniaSystemEnum) => {
  if (systemType === SymfoniaSystemEnum.Fk) return Tr.SavedAsFk;
  if (systemType === SymfoniaSystemEnum.Han) return Tr.SavedAsHan;
  if (systemType === (SymfoniaSystemEnum.Han ^ SymfoniaSystemEnum.Fk)) return Tr.SavedAsFkHan;
  return Tr.saveSuccess;
};

export const useSetInvoiceDownloaded = () => {
  const [setInvoiceDownloaded, loadingState] = useSetInvoiceDownloadedMutation({context: {envId: earchiveState.company.companyId}});

  const closeInvoiceDownloadedModal = () => {
    earchiveState.invoicesContext.set({setInvoiceDownloadedModalProps: undefined});
  };

  const setInvoiceDownloadedModalProps = (modalProps: InvoiceDownloadModalProps) => {
    earchiveState.invoicesContext.set({setInvoiceDownloadedModalProps: modalProps});
  };

  const confirmInvoiceDownloadedAction = useCallback(async (payload: MutationSetInvoiceDownloadedArgs, afterUpdateAction: () => void) => {
    try {
      const result = await setInvoiceDownloaded({
        variables: {
          Downloaded: payload.Downloaded,
          InvoiceId: payload.InvoiceId,
        },
      });
      const response = result.data?.SetInvoiceDownloaded;
      if (response?.Success) {
        earchiveState.alertsState.addAlert(
          <FormattedMessage id={successText(payload.Downloaded)}/>, ToastVariant.SUCCESS, {
            title: `${intl.formatMessage({id: Tr.invoicePURCHASE})}: ${earchiveState.invoicesContext.state.setInvoiceDownloadedModalProps?.invoiceNumber}`,
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          });
      } else {
        earchiveState.alertsState.addAlert(
          <FormattedMessage id={Tr.saveError}/>, ToastVariant.ERROR, {
            displayDuration: 10000,
            omitIfHasTheSameAlert: true,
          }
        );
      }
      afterUpdateAction();
    } catch (err) {
      closeInvoiceDownloadedModal();
      earchiveState.alertsState.addAlert(
        <FormattedMessage id={Tr.saveError}/>, ToastVariant.ERROR, {
          displayDuration: 10000,
          omitIfHasTheSameAlert: true,
        }
      );
    }
  }, [setInvoiceDownloaded]);

  return {closeInvoiceDownloadedModal, setInvoiceDownloadedModalProps, confirmInvoiceDownloadedAction, loadingState};
};
