import clsx from 'clsx';
import {FC, ReactNode, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';
import {Tr} from '../../../../locales/translationKeys';
import {Icon, IconColor, IconSvg, Modal} from '@symfonia/brandbook';
import {SimpleTable} from '../SimpleTable/SimpleTable';
import {getModalMaxWidth} from '@symfonia/symfonia-ksef-components';

export interface ModalWithTableProps {
  contentClassName?: string | undefined;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  title: ReactNode;
  content?: ReactNode;
  confirmButtonName?: string;
  cancelButtonName?: string;
  showCancelButton?: boolean;
  tableTitle?: string;
  tableRows?: ReactNode[][];
  tableHeaders?: ReactNode[];
  tableClassName?: string;
  showTableText: string;
  tableExpandable?: boolean;
}

export const ModalWithTable: FC<ModalWithTableProps> = ({
                                                          contentClassName,
                                                          onConfirm,
                                                          onCancel,
                                                          title,
                                                          content,
                                                          open,
                                                          cancelButtonName,
                                                          showCancelButton,
                                                          confirmButtonName,
                                                          tableTitle,
                                                          tableRows,
                                                          tableHeaders,
                                                          tableClassName,
                                                          showTableText,
                                                        }) => {
  const intl = useIntl();
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const modalContentSize = useMemo<string>(() => {
    return getModalMaxWidth();
  }, []);

  const cancel = () => {
    setIsTableExpanded(false);
    onCancel();
  };

  const confirm = () => {
    setIsTableExpanded(false);
    onConfirm();
  };
  
  return (
    <Modal onClose={cancel}
           centerButtons={true}
           show={open}
           title={title}
           className={clsx('font-bold', contentClassName, modalContentSize)}
           okButton={{
             text: confirmButtonName ? confirmButtonName : intl.formatMessage({id: Tr.yes}),
             onClick: confirm,
           }}
           cancelButton={showCancelButton ? {
             text: cancelButtonName ? cancelButtonName : intl.formatMessage({id: Tr.cancelButton}),
             onClick: cancel,
           } : undefined}>
      <div className="font-quicksand font-medium">
        {content}
        {!isTableExpanded && tableRows?.length &&
          <button type="button" style={{color: '#009c10'}} className="w-full pt-[24px] font-bold flex justify-center"
                  onClick={() => {
                    setIsTableExpanded(true);
                  }}
                  data-testId="ModalWithTableExpandButton">
            {showTableText ? showTableText : intl.formatMessage({id: Tr.showMissingUploadInvoicesButtonLabel})} <Icon
            svg={IconSvg.KEYBOARD_ARROW_DOWN} color={IconColor.PRIMARY_500}/>
          </button>}
        {isTableExpanded &&
          <SimpleTable title={tableTitle} rows={tableRows} headers={tableHeaders} className={clsx('pt-[24px] font-medium', tableClassName)}/>}
      </div>
    </Modal>
  );
};
