import {Tr} from '@symfonia-ksef/locales/keys';
import {FC, useState} from 'react';
import {intl} from '../../../../root/IntlProvider';
import {ButtonPrimary, ButtonPrimarySize, ButtonPrimaryVariant, Icon, IconColor, IconSize, IconSvg} from '@symfonia/brandbook';
import {DialogModal} from '@symfonia/symfonia-ksef-components';
import {SystemTypeSwitches} from './SystemTypeSwitches';
import {MutationSetInvoiceDownloadedArgs, SymfoniaSystem} from '@symfonia-ksef/graphql';

export interface InvoiceDownloadModalProps {
  modalIsActive: boolean;
  invoiceId: string;
  invoiceNumber?: string;
  currentSystemType?: SymfoniaSystem;
}

export const InvoiceDownloadedModal: FC<{
  confirm: (payload: MutationSetInvoiceDownloadedArgs) => Promise<void>;
  close: () => void;
  modalProps: InvoiceDownloadModalProps;
  isLoading: boolean;
}> = ({confirm, close, modalProps, isLoading}) => {
  const [systemType, setSystemType] = useState<number>();

  const onConfirm = async () => {
    await confirm({
      Downloaded: systemType,
      InvoiceId: modalProps.invoiceId,
    });
  };
  
  return (
    <DialogModal className={'max-w-[500px]'} open={modalProps.modalIsActive} onCancel={close} setMaxWidth={false} testId={`invoiceDownloadedStatusModal`}
      title={<div>{intl.formatMessage({id: Tr.SystemTypeSetAs})}</div>}>
      <p>{intl.formatMessage({id: Tr.invoicePURCHASE})}: <span className="font-bold">{modalProps.invoiceNumber}</span></p>
      <SystemTypeSwitches systemType={systemType ?? 0} setSystemType={setSystemType} currentSystem={modalProps.currentSystemType} testId="invoiceDownloadedStatusDropdown"/>
      <div className="flex justify-between my-4 w-full">
        <Icon svg={IconSvg.INFO} color={IconColor.ORANGE_500} size={IconSize.MD} className="basis-1/6"/>
        <div className="ml-2 flex-grow text-[#623E1C]">{intl.formatMessage({id: Tr.SaveInvoiceAsWarning})}</div>
      </div>
      <ButtonPrimary
        className="mt-4 w-1/2 block mx-auto"
        text={intl.formatMessage({id: Tr.save})}
        onClick={onConfirm}
        variant={ButtonPrimaryVariant.GREEN}
        size={ButtonPrimarySize.MD}
        testId="invoiceDownloadedStatusModalConfirmButton"
        disabled={!systemType}
        loading={isLoading}
      />
    </DialogModal>
  );
};
