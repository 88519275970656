import {Tr} from '@symfonia-ksef/locales/keys';
import {
  DropdownList,
  DropdownListOption,
  DropdownListWidth,
  Icon,
  IconColor,
  IconSize,
  Input,
  InputWidth,
} from '@symfonia/brandbook';
import {IconSvg, IconTag, IconTagSize} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {intl} from '../../root/IntlProvider';
import {emailValidator} from '../utils/utils';

type FilehubEmailListProps = {
  state: IFilehubState;
  filterCallback(valueToFind: string): {value: string; label: string}[];
  lengthToPhraseCheck: number;
  onValidationCallback(validationResult: boolean): void;
};

enum FilehubEmailListModes {
  DropMode,
  TextMode,
}

const FilehubEmailList = observer(
  ({state, filterCallback, lengthToPhraseCheck, onValidationCallback}: FilehubEmailListProps) => {
    const [newEmailText, setNewEmailText] = useState('');
    const [tmpEmailText, setTmpEmailText] = useState('');
    const [isTmpEmailTextVisible, setTmpEmailTextVisible] = useState(false);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [openDrop, setOpenDrop] = useState(false);
    const [emailValidationErrorList, setEmailValidationErrorList] = useState<string[]>([]);
    const initialValidationEmailRegX = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+');
    const [dropdownListOptions, setDropdownListOptions] = useState<DropdownListOption[]>([]);
    const [controlRunMode, setControlRunMode] = useState<FilehubEmailListModes>(FilehubEmailListModes.TextMode);

    const initialTextModeEmailValidation = (val: string) => {
      if (initialValidationEmailRegX.test(val)) {
        setTmpEmailTextVisible(true);
        setEmailValidationErrorList([]);
      } else setTmpEmailTextVisible(false);
    };

    const validateEmail = (val: string): boolean => {
      const errorList = emailValidator(val);
      setEmailValidationErrorList(errorList);

      return errorList.length === 0;
    };

    const checkDatasourceResponse = (val: string): {value: string; label: string}[] => {
      return filterCallback(val);
    };

    const createDropdownOptions = (options: {value: string; label: string}[]): DropdownListOption[] => {
      return options.map(({value, label}) => ({
        value,
        label,
      }));
    };

    const onInputChange = (val: any) => {
      setNewEmailText(val);
      setTmpEmailText(val);

      if (val.length < lengthToPhraseCheck) {
        setTmpEmailTextVisible(false);
        setDropdownVisible(false);
        setEmailValidationErrorList([]);
      } else {
        if (val.length === lengthToPhraseCheck) {
          const response = checkDatasourceResponse(val);
          if (response.length > 0) {
            setControlRunMode(FilehubEmailListModes.DropMode);
            setDropdownListOptions(createDropdownOptions(response));
            setDropdownVisible(true);
          } else {
            setControlRunMode(FilehubEmailListModes.TextMode);
            initialTextModeEmailValidation(val);
          }
        } else {
          if (val.length > lengthToPhraseCheck) {
            if (controlRunMode === FilehubEmailListModes.TextMode) initialTextModeEmailValidation(val);
            else {
              if (validateEmail(val)) onValidationCallback(true);
              else onValidationCallback(false);
            }
          }
        }
      }
    };

    const addNewEmailToList = () => {
      if (validateEmail(newEmailText)) {
        const newEmailList = [...state.emailListToSend, newEmailText];
        state.setEmailListToSendList(newEmailList);
        onValidationCallback(true);
      }

      setTmpEmailText('');
      onInputChange('');
      setEmailValidationErrorList([]);
    };

    const handleDropdownItemClick = (e: string[]) => {
      if (e.length > 0) onInputChange(e[0]);
      setDropdownVisible(false);
    };

    const reduceEmailList = (emailToDelete: string) => {
      const newEmailList = state.emailListToSend.filter(email => email !== emailToDelete);
      state.setEmailListToSendList(newEmailList);
      if (state.emailListToSend.length < 1) onValidationCallback(false);
    };

    return (
      <div>
        <span className="text-red-600">* </span>
        <span>{intl.formatMessage({id: Tr.emailAdres})}</span>
        <Input
          className="mt-[8px]"
          onInput={(val: any) => onInputChange(val)}
          width={InputWidth.FULL}
          value={tmpEmailText}
          placeholder={intl.formatMessage({id: Tr.setEmailAdres})}
          isError={false}
          onBlur={() => {
            if (tmpEmailText.length !== 0) validateEmail(tmpEmailText);
          }}
        />
        {isTmpEmailTextVisible && (
          <div className="block">
            <Input
              className="cursor-pointer"
              onClick={addNewEmailToList}
              onIconClick={addNewEmailToList}
              leftIcon={IconSvg.ADD}
              value={newEmailText}
              width={InputWidth.FULL}
              readonly
            />
          </div>
        )}
        {isDropdownVisible && (
          <div>
            <DropdownList
              isOpen={openDrop}
              className="mt-[8px]"
              selectable={false}
              options={dropdownListOptions}
              onChange={e => handleDropdownItemClick(e)}
              width={DropdownListWidth.FULL}
            />
          </div>
        )}
        <div className="mt-[10px]">
          {state.emailListToSend.length > 0 && (
            <div className="float flex flex-wrap items-center max-w-[410px] gap-[10px]">
              {state.emailListToSend.map((item, idx) => (
                <IconTag
                  size={IconTagSize.SM}
                  actionIcon={IconSvg.CLOSE}
                  onActionIconClick={() => {
                    reduceEmailList(item);
                  }}
                  text={item}
                  key={`${idx}`}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mt-[10px]">
          {emailValidationErrorList.length > 0 && (
            <div className="flex flex-col justify-start">
              {emailValidationErrorList.map(item => (
                <div className="font-bold text-red-600">
                  <Icon
                    svg={IconSvg.INFO}
                    color={IconColor.RED_500}
                    size={IconSize.MD}
                    className="align-middle mr-[5px]"
                  />
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default FilehubEmailList;
