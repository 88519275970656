import React, {FC, ReactNode} from 'react';
import {Icon, IconColor, IconSvg, Tooltip} from '@symfonia/brandbook';

export interface ModalHeaderWithIconProps {
  title: string;
  tooltipContent: string | ReactNode;
}

export const ModalHeaderWithIcon: FC<ModalHeaderWithIconProps> = ({title, tooltipContent}) => {
  return (
    <div className="relative flex gap-[4px]">
      {title}
      <Tooltip text={tooltipContent}>
        <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500}/>
      </Tooltip>
    </div>
  );
};
