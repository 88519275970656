import React, {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EnvironmentListItemFragment, EnvironmentType} from '@symfonia-ksef/graphql';
import {FormattedMessage, useIntl} from 'react-intl';
import {AppRoutes} from '../../../../root/Routes';
import {Tr} from '@symfonia-ksef/locales/keys';
import {isAdmin} from '../../../../common/helpers/checkCurrentPath';
import {ButtonTertiary, ButtonTertiarySize} from '@symfonia/brandbook';
import {ConfirmModal} from '@symfonia/symfonia-ksef-components';
import {observer} from 'mobx-react-lite';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';

export const ContextSwitcher = observer(() => {
  const {company, tenant} = earchiveState;
  const intl = useIntl();
  const navigate = useNavigate();
  const onChange = earchiveState.layoutState.setOpenDrawer;
  const [modalOpen, setModalOpen] = useState(false);


  const switchContextItem = useCallback(async (envToSwitch?: EnvironmentListItemFragment) => {
    if (!envToSwitch) {
      return;
    }
    setModalOpen(false);
    tenant.set({rootTenant: envToSwitch});
    await company.setEnvId(envToSwitch.EnvironmentId).fetch();

    if (envToSwitch?.Type === EnvironmentType.Tenant) {
      tenant.storeTenantId(envToSwitch?.Tenant?.Id);
      company.removeStoredCompanyId();
      onChange(false);
      navigate(AppRoutes.administration.address + '/modules/users');
    }

    if (envToSwitch?.Type === EnvironmentType.Company) {
      tenant.storeTenantId(envToSwitch?.Tenant?.Id);
      company.setCompanyWithFetch(envToSwitch.EnvironmentId);
      onChange(false);
      navigate(AppRoutes.eArchive.address + '/' + envToSwitch?.Tenant?.Id + '/company/' + envToSwitch?.EnvironmentId + '/documents/purchase');
    }
  }, [navigate, onChange]);

  const handleRedirectToHub = () => {
    tenant.set({
      moduleEnvironmentType: EnvironmentType.Company,
    });
    company.removeStoredCompanyId();
    tenant.removeStoredTenantId();
    onChange(false);
    navigate(AppRoutes.hub.address + '/' + company.tenantId);
  };

  const prepareConfirmModalContent = () => {
    if (isAdmin()) {
      return <FormattedMessage id={Tr.switchTenantPermissionContent}
                               values={{
                                 company: <span className={'ellipsis-w-modal'}
                                                style={{fontWeight: 'bold'}}>{tenant.state.selectedEnvForConfirmation?.Name}</span>,
                                 br: <br/>,
                               }}/>;
    }
    return <FormattedMessage id={Tr.switchCompanyPermissionContent}
                             values={{
                               company: <span className={'ellipsis-w-modal'}
                                              style={{fontWeight: 'bold'}}>{tenant.state.selectedEnvForConfirmation?.Name}</span>,
                               br: <br/>,
                             }}/>;
  };

  return (
    <>
      <ConfirmModal
        key={tenant.state.selectedEnvForConfirmation?.EnvironmentId}
        title={isAdmin() ? intl.formatMessage({id: Tr.switchTenantPermissionTitle}) : intl.formatMessage({id: Tr.switchCompanyPermissionTitle})}
        content={prepareConfirmModalContent()}
        confirmButtonName={intl.formatMessage({id: Tr.switchTenantContinueButton})}
        cancelButtonName={intl.formatMessage({id: Tr.switchTenantCancelButton})}
        open={modalOpen}
        contentClassName="contextSwitcherContent"
        onCancel={() => setModalOpen(false)}
        onConfirm={() => switchContextItem(tenant.state.selectedEnvForConfirmation)}
      />
      {(tenant.state.moduleEnvironmentType === EnvironmentType.Tenant ? tenant.state.rootTenant : company.currentEnvironment) && <div className={'mt-[20px] mb-[10px] w-full'}>
          <div className={'grid-cols-2 w-full flex mb-[10px]'}>
            <div>
              <p className={'font-quicksand text-sm font-normal mr-[5px]'}>
                {intl.formatMessage({id: Tr.tenant})}:
              </p>
            </div>
            <div>
              <p className={'font-quicksand text-sm font-bold break-words break-normal'}>
                {company.currentEnvironment?.Tenant?.Name ?? tenant.state.rootTenant?.Tenant?.Name}
              </p>
            </div>
          </div>
          <ButtonTertiary
            text={intl.formatMessage({id: Tr.redirectToHub})}
            onClick={handleRedirectToHub}
            size={ButtonTertiarySize.SM}
            testId='redirectToHubButton'
          />
        </div>}
    </>
  );
});
