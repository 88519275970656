import {FC} from 'react';
import {useSharedAccess} from './useSharedAccess';
import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {Icon, IconColor, IconSize, IconSvg, IconVariant, Toggle, Tooltip} from '@symfonia/brandbook';

export type SharedAccessSwitchProps =
  Omit<ReturnType<typeof useSharedAccess>, 'handleSharedAccessChange'>
  & { onChange: (isActive: boolean) => void, disabled?: boolean, isActive: boolean }

export const SharedAccessSwitch: FC<SharedAccessSwitchProps> = observer(({
                                                                           disabled,
                                                                           onChange,
                                                                           isActive,
                                                                           sharedAccessDisabled,
                                                                           sharedAccessSettled,
                                                                           sharedAccessLoading,
                                                                           modifiedBy,
                                                                           modificationDateTime,
                                                                           sharedAccessIsActive,
                                                                         }) => {
  const {formatMessage} = useIntl();

  return <><div className='flex items-center mt-[25px]'>
    <Toggle testId="sharedAccessSwitch" 
      value=""
      label={<FormattedMessage id={Tr.sharedAccessSwitchLabel}/>}
      checked={isActive} 
      disabled={disabled || sharedAccessDisabled}
      onChange={(status) => onChange(status)}/>
    <Tooltip position='right' text={formatMessage({id: Tr.sharedAccessInfo})}>
      <Icon svg={IconSvg.WARNING_CIRCLE} size={IconSize.LG} variant={IconVariant.CONTOUR} color={IconColor.BLUE1_500} className='ml-[10px]'/>
    </Tooltip>
  </div>
  {sharedAccessSettled && <div className='flex items-center mt-[15px]'>
    <Icon svg={IconSvg.WARNING_CIRCLE} size={IconSize.MD} variant={IconVariant.CONTOUR} color={IconColor.GREY_500} className='mr-[10px]'/>
    <span test-id="sharedAccessActivityInfoParagraph" className="text-grey-500">
      {formatMessage({id: Tr.sharedAccessActivationInfo}, {
         activation: formatMessage({id: sharedAccessIsActive ? Tr.whoActivated : Tr.whoDeactivated}),
           user: modifiedBy,
           dateTime: modificationDateTime,
      })}
    </span>
  </div>}  
  </>;
});
