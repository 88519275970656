import React, {FC, useCallback, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ContainerProps, DropzoneProps, InputProps} from '../FilesUploader/DropzoneUploaderModule/DropzoneContainer';
import {uploadInvoicesService} from '../../../../state/rootRepository';
import {useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {
  DropzoneUploaderStateServicePublicI,
  FileItem,
} from '../FilesUploader/DropzoneUploaderModule/DropzoneUploaderStateService';
import {Text} from '../../../root/components/Text';
import {FileListItem} from './FileListItem';
import {ButtonPrimary, ButtonSecondary} from '@symfonia/brandbook';
import {intl} from '../../../root/IntlProvider';
import classnames from 'classnames';

type UploadXMLFilesListProps = {
  multiple: boolean;
  scrolledList?: boolean;
  state: DropzoneUploaderStateServicePublicI
} & InputProps & DropzoneProps & Pick<ContainerProps, 'handleRemove'>

export const UploadXMLFilesList: FC<UploadXMLFilesListProps> = observer(({
                                                                           multiple,
                                                                           onDrop,
                                                                           onDragOver,
                                                                           state,
                                                                           triggerUploadWindow,
                                                                           inputRef,
                                                                           handleRemove,
                                                                           scrolledList,
                                                                         }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {jobRunner: {loading}} = uploadInvoicesService;
  const areMultipleFilesSelected = state.validatedFiles.length > 1;

  const onSubmit = useCallback(async (acceptedFiles: FileItem[] | undefined) => {
    await uploadInvoicesService.sendInvoicesFiles(acceptedFiles?.map?.(({file}) => file));
    navigate(location.state?.from ?? '../documents');
  }, [uploadInvoicesService]);

  const filesListContent = useMemo(() => state.allFiles.map((item) => {
    return <FileListItem key={state.createFileKey(item.file)} fileItem={item} removeFile={handleRemove}/>;
  }), [state.allFiles, handleRemove]);

  if (state.allFiles.length === 0) {
    return null;
  }

  return (
    <div>
      {areMultipleFilesSelected ?
        <Text block className="py-[8px]">
          <FormattedMessage id={Tr.numberOfAddedDocuments} values={{
            countDocuments:
              <strong>{state.validatedFiles.length + ' / ' + (state.validatedFiles.length + state.rejectedFiles.length)}</strong>,
          }}/>
        </Text>
        :
        <Text block className="py-[16px] text-left ml-[24px]"><FormattedMessage
          id={Tr.addedFile}/>:</Text>
      }
      <div onDrop={onDrop} onDragOver={onDragOver}>
        <div className={classnames('mr-[8px] p-[8px]', {
          'overflow-y-scroll max-h-[300px]': scrolledList,
        })}>{filesListContent}</div>
        <div className="mt-[20px] flex justify-center">
        <ButtonSecondary
            className="mr-[8px]"
            disabled={loading}
            onClick={triggerUploadWindow}
            text={intl.formatMessage({id: multiple ? Tr.chooseFileFromDisk : Tr.swapTheFile}, {i: 'i'})}
            testId='openUploadWindowButton'
          />
          <ButtonPrimary
            className="mr-[8px]"
            loading={loading}
            onClick={() => onSubmit(state.validatedFiles)}
            text={intl.formatMessage({id: Tr.endAddingFiles})}
            testId='uploadSelectedXmlFilesButton'
          />
        </div>
      </div>
    </div>
  );
});
